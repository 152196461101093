import React, { forwardRef } from "react";
import { LabeledInputProps } from "./LabeledInputProps";
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import FormErrorText from "../FormErrorText/FormErrorText";
import FormTitleText from "../FormTitleText/FormTitleText";

const LabeledInput = forwardRef<any, LabeledInputProps>(
  ({ title, error, rightView, leftView, ...rest }, ref) => (
    <Stack w="full" spacing={0}>
      <Stack>
        {title && <FormTitleText>{title}</FormTitleText>}
        <InputGroup>
          <Input
            ref={ref}
            bg="black"
            borderWidth={1}
            isInvalid={!!error}
            borderRadius={8}
            errorBorderColor="red"
            fontSize={16} // This prevents safari from zooming in on the input
            fontFamily="Orbitron, sans-serif"
            {...rest}
          />
          {rightView && (
            <InputRightElement w="fit-content">{rightView}</InputRightElement>
          )}
          {leftView && (
            <InputLeftElement h="full" w="fit-content" pl={4}>
              {leftView}
            </InputLeftElement>
          )}
        </InputGroup>
      </Stack>
      {error && <FormErrorText>{error}</FormErrorText>}
    </Stack>
  ),
);

export default LabeledInput;
