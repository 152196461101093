/* Gegrüßet seist du, Maria, voll der Gnade der Herr ist mit dir. Du bist gebenedeit unter den Frauen,
und gebenedeit ist die Frucht deines Leibes, Jesus Heilige Maria, Mutter Gottes bitte für uns Sünder
jetzt und in der Stunde unseres Todes Amen
*/

import { Box, Button,Image,  Flex, Input, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalCloseButton, ModalBody, ModalFooter, Text, CloseButton, Spinner } from '@chakra-ui/react'
import React from 'react'
import ReactPlayer from 'react-player'
import { useEffect, useState, useRef } from 'react'
import { LiaCameraRetroSolid } from "react-icons/lia";
import FundedCard from '../components/FundedCard'
import { Textarea } from "@chakra-ui/react";
import useShowToast from "../hooks/useShowToast";
import { useMe } from "../providers/me-provider";
import useUploadFiles, {
  LocalFileViewer,
  supportedMediaTypes,
} from "../utils/use-upload-files";
import { useApplyForFundingMutation } from "./networking/__generated__/apply-for-funding-mutation.generated";

const FundingPage = () => {
  const { me } = useMe();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [asset, setAsset] = useState(null);
  const [localFile, setLocalFile] = useState(null);
  const { upload } = useUploadFiles();
  const fileInputRef = useRef(null);

  const showToast = useShowToast();

  const handleMediaChange = async (event) => {
    const fileList = event.target.files;

    if (!fileList) {
      return;
    }

    const files = Array.from(fileList);
    const file = files[0];

    setAsset(undefined);
    setProgress(0);
    setLocalFile(file);
    setLoading(true);

    try {
      const asset = await upload(file, setProgress);
      setAsset(asset);
    } catch (error) {
      showToast("Error", error.message, "error");
      setLocalFile(undefined);
    } finally {
      setLoading(false);
    }
  };

  const [applyForFundingAPI] = useApplyForFundingMutation();

  const submitForFunding = async (e) => {
    e.preventDefault();

    try {
      await applyForFundingAPI({
        variables: {
          input: {
            name,
            email,
            title,
            summary,
            // @ts-ignore
            assetIds: asset ? [asset.id] : [],
          },
        },
      });
      setIsOpen(false);
      showToast("Success", "Submission successful!", "success");
    } catch (err) {
      showToast(
        "Error",
        "Failed to submit. Please review and try again. " + err.message,
        "error",
      );
    }
  };

  const handleOpen = async () => {
    if (me) {
      setName(me.displayName);
      setEmail(me.email);
    }
    setIsOpen(true);
  };

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Flex>
        <Text
          fontWeight="bold"
          fontFamily={"Orbitron, sans-serif"}
          ml={100}
          whiteSpace="nowrap"
          color="black"
          mt="-15px"
          fontSize="4xl"
          style={{ WebkitTextStroke: "1px rgb(135,206,250)" }}
        >
          Got Funding?
        </Text>
        <Box ml={-450} mt={10}>
          <ReactPlayer url="https://www.youtube.com/watch?v=dQw4w9WgXcQ" />
        </Box>
        <Text ml={-640} mt={420} fontFamily={"Orbitron, sans-serif"}>
          At Ark, we believe in empowering innovators. We do this not only by
          our media presence and makretplace, but also by investing in ideas we
          believe can change the world. If you beleive you can change the world,
          submit your idea to us today.
        </Text>
      </Flex>

      <Button
        ml={150}
        mt={25}
        colorScheme="blue"
        fontFamily={"Orbitron, sans-serif"}
        variant="solid"
        onClick={handleOpen}
      >
        Apply
      </Button>

      <Modal onClose={handleClose} size="xl" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent bg={"black"}>
          <ModalHeader
            fontFamily={"Orbitron, sans-serif"}
            color="rgb(135,206,250)"
          >
            <Text textAlign="center">Submission</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Orbitron, sans-serif"} color="rgb(135,206,250)">
              Name
            </Text>
            <Input
              placeholder="Type your name here..."
              fontFamily={"Orbitron, sans-serif"}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Text
              mt={4}
              fontFamily={"Orbitron, sans-serif"}
              color="rgb(135,206,250)"
            >
              Email
            </Text>
            <Input
              placeholder="Type your email here..."
              fontFamily={"Orbitron, sans-serif"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <Text
              mt={4}
              fontFamily={"Orbitron, sans-serif"}
              color="rgb(135,206,250)"
            >
              Subject
            </Text>
            <Text
              fontFamily={"Orbitron, sans-serif"}
              color="white"
              backgroundColor="black"
              padding="10px"
              borderRadius="5px"
            >
              Funding Submission by {name}
            </Text>

            <Text
              mt={4}
              fontFamily={"Orbitron, sans-serif"}
              color="rgb(135,206,250)"
            >
              {" "}
              Title of Idea{" "}
            </Text>
            <Input
              placeholder="Type title here"
              fontFamily={"Orbitron, sans-serif"}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <Text
              mt={4}
              fontFamily={"Orbitron, sans-serif"}
              color="rgb(135,206,250)"
            >
              {" "}
              Summary of Idea{" "}
            </Text>
            <Textarea
              placeholder="Summarize what your product is about"
              fontFamily={"Orbitron, sans-serif"}
              height="200px"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
            />

            <Text
              mt={4}
              fontFamily={"Orbitron, sans-serif"}
              color="rgb(135,206,250)"
            >
              {" "}
              Submit Media{" "}
            </Text>

            <div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleMediaChange}
                accept={supportedMediaTypes.join(", ")}
              />
              <Button onClick={() => fileInputRef.current.click()}>
                <LiaCameraRetroSolid />
              </Button>
            </div>

            {loading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10vh",
                  transform: "translateY(20px)",
                }}
              >
                <Text
                  fontFamily={"Orbitron, sans-serif"}
                  color="rgb(135,206,250)"
                  style={{ marginBottom: "20px" }}
                >
                  Uploading... {(progress * 100).toFixed(2)}%
                </Text>
                <Spinner />
              </div>
            )}
            {!loading && localFile && (
              <Flex mt={5} w={"full"} position={"relative"}>
                <LocalFileViewer localFile={localFile} />
                <CloseButton
                  onClick={() => {
                    setAsset(undefined);
                    setLocalFile(undefined);
                  }}
                  bg={"gray.800"}
                  position={"absolute"}
                  top={2}
                  right={2}
                />
              </Flex>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={submitForFunding}
              style={{
                backgroundColor: "black",
                color: "#FF6EC7",
                borderColor: "#FF6EC7",
                borderWidth: "2px",
                fontFamily: "Orbitron, sans-serif",
              }}
            >
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex>
        <Text
          fontWeight="bold"
          fontFamily={"Orbitron, sans-serif"}
          color="black"
          mt={30}
          whiteSpace="nowrap"
          ml={100}
          fontSize="4xl"
          style={{ WebkitTextStroke: "1px rgb(135,206,250)" }}
        >
          {" "}
          Ark Investments{" "}
        </Text>
        <Text></Text>
        <Flex ml={-500} mt={40} width="300px">
          <FundedCard
            img="/retvrn.jpg"
            name="RETVRN"
            description="RETVRN was founded by Nicholas Stumphauzer with the intent of providing users of technology with greater degrees of control over their devices in an age where tech is demaning increasingly more from us"
          />
        </Flex>
        <Flex ml={0} mt={40} width="300px">
          <FundedCard
            img="/modlee.jpg"
            link="https://www.modlee.ai/"
            name="Modlee"
            description="Modlee is a pioneer
in the AI development and is optimizing the Machine Learning process"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default FundingPage