/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

// This is place the vidoes and images are processed for conversation
//Sometimes it wont let me put multiple images, when I click them it closes and nothing renders, 
//see if this is an error on my machine or everywhere

import {
  Flex,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { IoSendSharp } from "react-icons/io5";
import useShowToast from "../hooks/useShowToast";
import { LiaCameraRetroSolid } from "react-icons/lia";
import { BsFillImageFill } from "react-icons/bs";
import ReactPlayer from "react-player";
import { useSearchParams } from "react-router-dom";
import { useSendMessageMutation } from "../pages/networking/__generated__/send-message-mutation.generated";
import { addNewMessageToCache } from "../utils/add-new-message-to-cache";
import useUploadFiles, {
  LocalFileViewer,
  supportedMediaTypes,
} from "../utils/use-upload-files";

const MessageInput = ({ setMessages }) => {
  const [messageText, setMessageText] = useState("");
  const showToast = useShowToast();
  const imageRef = useRef(null);
  const { onClose } = useDisclosure();
  const [loadingImage, setLoadingImage] = useState(false);
  const [searchParams] = useSearchParams();
  const selectedConversationId = searchParams.get("id");

  const [asset, setAsset] = useState();
  const [localFile, setLocalFile] = useState();
  const [progress, setProgress] = useState(0);
  const { upload } = useUploadFiles();

  const [
    sendMessageAPI,
    { error: sendMessageError, loading: sendMessageLoading },
  ] = useSendMessageMutation();

  useEffect(() => {
    if (!sendMessageError) {
      return;
    }

    showToast("Error", sendMessageError.message, "error");
  }, [sendMessageError]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!messageText && !asset) return;
    if (sendMessageLoading) return;

    try {
      await sendMessageAPI({
        variables: {
          input: {
            conversationId: selectedConversationId,
            text: messageText,
            assetIds: asset ? [asset.id] : [],
          },
        },
        update: (cache, { data }) => {
          if (!data?.message) {
            return data;
          }

          addNewMessageToCache(
            cache,
            selectedConversationId,
            data.message,
            searchParams,
          );
        },
      });

      setMessageText("");
      setAsset(undefined);
      setLocalFile(undefined);
    } catch (error) {
      console.error("Error in handleSendMessage:", error);
      showToast("Error", error.message, "error");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleMediaChange = async (event) => {
    const fileList = event.target.files;

    if (!fileList) {
      return;
    }

    const files = Array.from(fileList);
    const file = files[0];

    setAsset(undefined);
    setProgress(0);
    setLocalFile(file);
    setLoadingImage(true);

    try {
      const asset = await upload(file, setProgress);
      setAsset(asset);
    } catch (error) {
      showToast("Error", error.message, "error");
      setLocalFile(undefined);
    } finally {
      setLoadingImage(false);
    }
  };

  return (
    <Flex gap={2} alignItems={"center"}>
      <form
        onSubmit={handleSendMessage}
        style={{ flex: 95 }}
        fontFamily={"Obritron, sans-serif"}
      >
        <InputGroup>
          <Input
            w={"full"}
            placeholder="Type a message"
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={handleKeyDown} // Use onKeyDown here
            value={messageText}
            fontFamily={"Orbitron, sans-serif"}
          />
          <InputRightElement onClick={handleSendMessage} cursor={"pointer"}>
            <IoSendSharp />
          </InputRightElement>
        </InputGroup>
      </form>
      <Flex flex={5} cursor={"pointer"}>
        <LiaCameraRetroSolid
          style={{ marginLeft: "5px", cursor: "pointer" }}
          size={24}
          color="#FF6EC7"
          onClick={() => imageRef.current.click()}
        />
        <Input
          type={"file"}
          hidden
          ref={imageRef}
          onChange={handleMediaChange}
          accept={supportedMediaTypes.join(", ")}
        />
      </Flex>
      <Modal
        isOpen={localFile}
        onClose={() => {
          onClose();
          setAsset(undefined);
          setLocalFile(undefined);
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {loadingImage && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10vh",
                  transform: "translateY(20px)",
                }}
              >
                <Text
                  fontFamily={"Orbitron, sans-serif"}
                  color="rgb(135,206,250)"
                  style={{ marginBottom: "20px" }}
                >
                  Uploading... {(progress * 100).toFixed(2)}%
                </Text>
                <Spinner />
              </div>
            )}
            {!loadingImage && (
              <Flex mt={5} w={"full"}>
                <LocalFileViewer localFile={localFile} />
              </Flex>
            )}
            <Flex justifyContent={"flex-end"} my={2}>
              {!sendMessageLoading ? (
                <IoSendSharp
                  size={24}
                  cursor={"pointer"}
                  onClick={(e) => {
                    if (asset) {
                      handleSendMessage(e);
                    }
                  }}
                />
              ) : (
                <Spinner size={"md"} />
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default MessageInput;
