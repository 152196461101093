/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { Avatar, useMediaQuery } from "@chakra-ui/react";
import { Box, Flex, Link, Text, VStack } from "@chakra-ui/layout";
import { Menu } from "@chakra-ui/menu";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { IoPeopleOutline } from "react-icons/io5";
import { MdAddBox } from "react-icons/md";
import { useMe } from "../providers/me-provider";
import useToggleFollowUser from "../hooks/useToggleFollowUser";
import { useFollowersQuery } from "../hooks/networking/__generated__/followers-query.generated";
import { useFollowersCountQuery } from "../hooks/networking/__generated__/followers-count-query.generated";
import useShowToast from "../hooks/useShowToast";

const UserHeader = ({ user }) => {
  const { me: currentUser } = useMe();

  const {
    isOpen: isFollowersOpen,
    onOpen: onFollowersOpen,
    onClose: onFollowersClose,
  } = useDisclosure();

  const {
    toggleFollowUser,
    submitting,
    isFollowingUser,
    following,
    totalFollowingCount,
  } = useToggleFollowUser({
    userId: user.id,
  });
  const { data: followersData, error: followersError } = useFollowersQuery({
    variables: {
      userId: user.id,
      limit: 100,
    },
    fetchPolicy: "cache-first",
    skip: !isFollowersOpen,
  });
  const { data: followersCountData, error: followersCountError } =
    useFollowersCountQuery({
      variables: {
        userId: user.id,
      },
      fetchPolicy: "cache-first",
    });
  const totalFollowersCount =
    followersCountData?.followers.pageInfo.totalEdges ?? 0;

  const showToast = useShowToast();
  const {
    isOpen: isFollowingOpen,
    onOpen: onFollowingOpen,
    onClose: onFollowingClose,
  } = useDisclosure();
  const [isSmallScreen] = useMediaQuery(
    "(min-width: 375px) and (min-height: 667px)",
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isIpadEsq] = useMediaQuery(
    "(min-width: 768px) and (min-height: 1024px)",
  );
  const [ipadPro] = useMediaQuery(
    "(min-width: 1024px) and (min-height: 1366px)",
  );
  const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");
  const [isIphone] = useMediaQuery([
    "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)",
  ]);
  const { isOpen: isBioOpen, onClose: onBioClose } = useDisclosure();

  useEffect(() => {
    if (!followersError && !followersCountError) {
      return;
    }

    showToast(
      "Error",
      followersError?.message ?? followersCountError?.message,
      "error",
    );
  }, [followersError, followersCountError]);

  return (
    <VStack
      gap={4}
      alignItems="flex-start"
      border="1px"
      borderColor="yellow.400"
      borderRadius="lg"
      width={{ base: "full", md: "130%", lg: ipadPro ? "130%" : "full" }} // Wider for iPad Pro
      mr={{ md: "500" }}
      ml={ipadPro ? "-180" : isIpadEsq ? "-170px" : "0"}
    >
      <Flex direction="row" alignItems="center" width="100%">
        <Flex direction="column" alignItems="center">
          <Box
            border="1.5px solid"
            borderColor="yellow.400"
            borderRadius="full"
            mt={isMac ? 10 : isIpadEsq ? 19 : isSmallScreen ? -49 : 3}
            ml={isIpadEsq ? "5" : "3"}
            mb={isIpadEsq ? "20" : "10"}
          >
            {user.avatarAsset?.url ? (
              <Avatar
                name={user.displayName}
                src={user.avatarAsset.url}
                size={{
                  base: "xl",
                  md: "2xl",
                }}
              />
            ) : (
              <Avatar
                name={user.displayName}
                src="https://bit.ly/broken-link"
                size={{
                  base: "xl",
                  md: "2xl",
                }}
              />
            )}
          </Box>
          {currentUser?.id === user.id && (
            <Link
              as={RouterLink}
              to="/update"
              ml={2}
              mt={isSmallScreen ? 20 : 4}
            >
              <Button
                size={"sm"}
                color={"green.400"}
                fontFamily={"Orbitron, sans-serif"}
                borderColor={"green.500"}
                borderWidth={2}
                mt={
                  isMac
                    ? "-150px"
                    : isIpadEsq
                      ? "-150px"
                      : isSmallScreen
                        ? "-200px"
                        : "0"
                }
              >
                Update Profile
              </Button>
            </Link>
          )}
          {currentUser?.id !== user.id && (
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  left: isMac
                    ? "-30px"
                    : ipadPro
                      ? "290px"
                      : isIpadEsq
                        ? "290px"
                        : isSmallScreen
                          ? "-28px"
                          : "-30px",
                  top: isMac
                    ? "-210px"
                    : ipadPro
                      ? "-95px"
                      : isIpadEsq
                        ? "-95px"
                        : isSmallScreen
                          ? "25px"
                          : "-30px",
                }}
              >
                <Button
                  size={"sm"}
                  onClick={toggleFollowUser}
                  isLoading={submitting}
                  fontFamily={"Orbitron, sans-serif"}
                  style={{
                    transform: isIpadEsq ? "translateX(-10px)" : "none",
                  }}
                >
                  {isFollowingUser ? "Unfollow" : "Follow"}
                </Button>
              </div>
            </div>
          )}
        </Flex>
        <Flex
          direction="column"
          alignItems="flex-start"
          ml={5}
          mb={10}
          transform={isSmallScreen ? "translateY(-65px)" : "none"}
          flexGrow={1}
          minWidth="0"
        >
          <Flex direction="row" alignItems="center" width="100%">
            <Text
              fontSize={"2xl"}
              fontWeight={"bold"}
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
              mt={isIpadEsq ? "50px" : "0"}
              flexGrow={1}
              whiteSpace="normal"
              overflow="hidden"
              textOverflow="clip"
            >
              {user.username}
            </Text>
            {/* The people button on isSmallScreen */}
            <Flex gap={2} alignItems={"center"} ml={2} direction="row">
              <Flex
                direction="column"
                justifyContent={isSmallScreen ? "flex-start" : "center"}
                alignItems={isSmallScreen ? "flex-start" : "center"}
              >
                {!isIpadEsq && (
                  <Button
                    color={"#FF6EC7"}
                    fontFamily={"Orbitron, sans-serif"}
                    onClick={isSmallScreen ? undefined : onFollowersOpen}
                    mb={5}
                    style={
                      isSmallScreen
                        ? {
                            marginLeft: "0px",
                            marginTop: "400px",
                            border: "none",
                            background: "transparent",
                            color: "transparent",
                          }
                        : {}
                    }
                  >
                    {(!isSmallScreen || isMac) && !isIphone && (
                      <Text
                        color={"#FF6EC7"}
                        fontFamily={"Orbitron, sans-serif"}
                        mt={isMac ? "-700" : "0"}
                        mr={isMac ? "500" : "0"}
                        onClick={onFollowersOpen}
                      >
                        {totalFollowersCount} followers
                      </Text>
                    )}
                  </Button>
                )}

                {/*  People Button for phones*/}
                {(isSmallScreen || isIphone) &&
                !isIpadEsq &&
                !ipadPro &&
                !isMac ? (
                  <Button
                    onClick={onOpen}
                    Color={isMac ? "pink" : "transparent"}
                    style={{
                      position: "absolute",
                      top: isIphone
                        ? "320px"
                        : isMac
                          ? "225px"
                          : isSmallScreen
                            ? "280px"
                            : "260px",
                      right: "20px",
                      fontSize: isMac ? "24px" : "16px",
                      overflow: "visible",
                    }}
                  >
                    <IoPeopleOutline
                      color="#FF6EC7"
                      style={{ pointerEvents: "none" }}
                    />
                  </Button>
                ) : null}
                {(isMac || isIpadEsq || (!isSmallScreen && !isIphone)) && (
                  <Button
                    color={"#FF6EC7"}
                    fontFamily={"Orbitron, sans-serif"}
                    onClick={onFollowingOpen}
                    style={
                      isMac
                        ? { marginTop: "-375px" }
                        : isIpadEsq
                          ? { marginTop: "140px" }
                          : {}
                    }
                  >
                    <Text color={"#FF6EC7"} fontFamily={"Orbitron, sans-serif"}>
                      {totalFollowingCount} following
                    </Text>
                  </Button>
                )}
              </Flex>

              {isIpadEsq && (
                <Button
                  color={"#FF6EC7"}
                  fontFamily={"Orbitron, sans-serif"}
                  onClick={onFollowersOpen}
                  mb={5}
                  width={"125px"}
                  style={{ marginTop: "50px", marginLeft: "-135px" }}
                >
                  <Text color={"#FF6EC7"} fontFamily={"Orbitron, sans-serif"}>
                    {totalFollowersCount} followers
                  </Text>
                </Button>
              )}

              {/* Modal for small Screen */}
              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader
                    fontFamily={"Orbitron, sans-serif"}
                    color="rgb(135,206,250)"
                    textAlign="center"
                    bg="black"
                  >
                    Stats
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody bg="black">
                    <Flex direction="column">
                      <Button
                        color={"#FF6EC7"}
                        fontFamily={"Orbitron, sans-serif"}
                        onClick={onFollowersOpen}
                        mb={5}
                        width={"125px"}
                      >
                        <Text
                          color={"#FF6EC7"}
                          fontFamily={"Orbitron, sans-serif"}
                        >
                          {totalFollowersCount} followers
                        </Text>
                      </Button>

                      <Box
                        w="1"
                        h="1"
                        bg={"gray.light"}
                        borderRadius={"full"}
                      ></Box>
                      <Link color={"gray.light"}></Link>
                      <Flex direction="column" mt={-55} ml={140}>
                        <Box
                          border="1px"
                          borderColor="yellow.200"
                          borderRadius="md"
                          width="200px"
                          bg="black"
                        >
                          <Box
                            bg="#FF6EC7"
                            borderRadius="md"
                            height="20px"
                            width={`${(totalFollowersCount / 1000) * 100}%`}
                          />
                        </Box>
                      </Flex>

                      <Button
                        color={"#FF6EC7"}
                        fontFamily={"Orbitron, sans-serif"}
                        onClick={onFollowingOpen}
                        mt={10}
                        width={"125px"}
                      >
                        <Text
                          color={"#FF6EC7"}
                          fontFamily={"Orbitron, sans-serif"}
                        >
                          {totalFollowingCount} following
                        </Text>
                      </Button>
                    </Flex>
                  </ModalBody>
                  <ModalFooter bg="black"></ModalFooter>
                </ModalContent>
              </Modal>

              <Modal isOpen={isFollowersOpen} onClose={onFollowersClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader
                    fontFamily={"Orbitron, sans-serif"}
                    color="rgb(135,206,250)"
                    bg="black"
                  >
                    Followers
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody bg="black">
                    {followersData?.followers.edges.map(
                      ({ node: follower }, index) => (
                        <Flex
                          key={follower.id || index}
                          alignItems="center"
                          mb={2}
                        >
                          <Link
                            as={RouterLink}
                            to={`/${follower.username}`}
                            onClick={onFollowersClose}
                          >
                            <Avatar
                              src={follower.avatarAsset?.url}
                              name={follower.username}
                            />
                          </Link>
                          <Text
                            ml={2}
                            fontFamily={"Orbitron, sans-serif"}
                            color="rgb(135,206,250)"
                          >
                            {follower.username}
                          </Text>
                        </Flex>
                      ),
                    )}
                  </ModalBody>
                </ModalContent>
              </Modal>

              <Modal isOpen={isFollowingOpen} onClose={onFollowingClose}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader
                    fontFamily={"Orbitron, sans-serif"}
                    color="rgb(135,206,250)"
                    bg="black"
                  >
                    Following
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody bg="black">
                    {following.map((user, index) => (
                      <Flex key={user.id || index} alignItems="center" mb={2}>
                        <Link
                          as={RouterLink}
                          to={`/${user.username}`}
                          onClick={onFollowingClose}
                        >
                          <Avatar
                            src={user.avatarAsset?.url}
                            name={user.username}
                          />
                        </Link>
                        <Text
                          ml={2}
                          fontFamily={"Orbitron, sans-serif"}
                          color="rgb(135,206,250)"
                        >
                          {user.username}
                        </Text>
                      </Flex>
                    ))}
                  </ModalBody>
                </ModalContent>
              </Modal>

              {/* Follower Bar  */}

              {(isMac || isIpadEsq || (!isSmallScreen && !isIphone)) && (
                <>
                  <Box
                    w="1"
                    h="1"
                    bg={"gray.light"}
                    borderRadius={"full"}
                  ></Box>
                  <Link color={"gray.light"}></Link>
                  <Flex
                    direction="column"
                    mt={-50}
                    style={
                      isMac
                        ? { marginTop: "27px", marginLeft: "-520px" }
                        : isIpadEsq
                          ? { marginTop: "30px", marginLeft: "-30px" }
                          : {}
                    }
                  >
                    <Box
                      border="1px"
                      borderColor="yellow.200"
                      borderRadius="md"
                      width={isIpadEsq ? "175px" : "150px"}
                      bg="black"
                    >
                      <Box
                        bg="#FF6EC7"
                        borderRadius="md"
                        height="20px"
                        width={`${(totalFollowersCount / 1000) * 100}%`}
                      />
                    </Box>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      {/* Bio Button */}

      <Modal isOpen={isBioOpen} onClose={onBioClose} color="red">
        <ModalOverlay />
        <ModalContent bg="black">
          <ModalHeader>
            <Box
              fontFamily={"Orbitron, sans-serif"}
              color={"rgb(135, 206, 250)"}
              ml={40}
            >
              Credentials
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              Full Name
            </Text>
            <Text
              fontSize={"sm"}
              mr={10}
              fontFamily={"Orbitron, sans-serif"}
              mt={isIpadEsq ? -10 : 0}
            >
              {user.displayName}
            </Text>
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              Bio
            </Text>
            <Text
              mr={190}
              color={"gray.200"}
              fontFamily={"Orbitron, sans-serif"}
              mt={isIpadEsq ? 0 : isSmallScreen ? 0 : 0}
            >
              {user.about}
            </Text>
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              Skills
            </Text>
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              {user.skills}
            </Text>
            <MdAddBox />
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              Previous Projects
            </Text>
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              {user.projects}
            </Text>{" "}
            <MdAddBox />
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              Education
            </Text>
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              {user.education}
            </Text>{" "}
            <MdAddBox />
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            ></Text>
            <Text
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            ></Text>
          </ModalBody>

          <ModalFooter>
            <Button
              fontFamily={"Orbitron, sans-serif"}
              colorScheme="blue"
              mr={3}
              onClick={onBioClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex
        w={"full"}
        justifyContent={"space-between"}
        mb={isSmallScreen ? -75 : 2}
        ml={125}
      >
        <Flex>
          <Box className="icon-container">
            <Menu></Menu>
          </Box>
        </Flex>
      </Flex>
    </VStack>
  );
};

export default UserHeader;
