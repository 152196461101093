import React from "react";
import Props from "./FormTitleTextProps";
import { Text } from "@chakra-ui/react";

const FormTitleText = ({ children, ...rest }: Props) => (
  <Text
    fontFamily="Orbitron, sans-serif"
    fontSize="xs"
    fontWeight="bold"
    {...rest}
  >
    {children}
  </Text>
);

export default FormTitleText;
