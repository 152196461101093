import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { ConversationFragmentFragmentDoc } from './conversation-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConversationQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ConversationQuery = { __typename?: 'Query', conversation?: { __typename?: 'Conversation', id: string, favorited: boolean, read: boolean, sortBy: any, otherParticipants: Array<{ __typename?: 'User', id: string, displayName: string, username: string, presence: Types.Presence, avatarAsset?: { __typename?: 'Asset', url: string, id: string } | null }>, lastMessage?: { __typename?: 'Message', id: string, text?: string | null, userId: string, createdAt: any, assets: Array<{ __typename?: 'Asset', id: string, url: string, type: Types.AssetType }> } | null } | null };


export const ConversationDocument = gql`
    query Conversation($id: String!) {
  conversation(id: $id) {
    ...ConversationFragment
  }
}
    ${ConversationFragmentFragmentDoc}`;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<ConversationQuery, ConversationQueryVariables> & ({ variables: ConversationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
        }
export function useConversationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationSuspenseQueryHookResult = ReturnType<typeof useConversationSuspenseQuery>;
export type ConversationQueryResult = Apollo.QueryResult<ConversationQuery, ConversationQueryVariables>;