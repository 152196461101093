import React, { ReactNode, createContext, useContext, useMemo } from "react";
import {
  MeQuery,
  useMeQuery,
} from "./networking/__generated__/me-query.generated";
import useLocalStorageState from "use-local-storage-state";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import useShowToast from "../hooks/useShowToast";

export interface ILogInAsAdminUser {
  id: string;
  username: string;
  displayName: string;
  avatarUrl: string | undefined;
}

interface IMe {
  me: MeQuery["selectedUser"] | undefined;
  isAuthenticated: boolean;
  isOnboarding: boolean;
  adminLoggedInAsUser: ILogInAsAdminUser | undefined;
  refreshMe: () => void;
  authenticate: (redirectToPath?: string) => void;
  logOut: () => Promise<void>;
  adminLogInAsUser: (user: ILogInAsAdminUser) => void;
  adminLogOutAsUser: () => void;
}

export const MeContext = createContext<IMe>({
  me: undefined,
  isAuthenticated: false,
  isOnboarding: false,
  adminLoggedInAsUser: undefined,
  refreshMe: () => {},
  authenticate: () => {},
  logOut: async () => {},
  adminLogInAsUser: () => {},
  adminLogOutAsUser: () => {},
});

export const MeProvider = ({ children }: { children: ReactNode }) => {
  const session = useSessionContext();
  const navigate = useNavigate();
  const showToast = useShowToast();

  const [
    adminLoggedInAsUser,
    setAdminLoggedInAsUser,
    { removeItem: deleteAdminLoggedInAsUser },
  ] = useLocalStorageState<ILogInAsAdminUser>("ark.adminLoggedInAsUser");

  const { data, refetch } = useMeQuery({
    skip: session.loading || !session.userId,
  });

  const me = data?.selectedUser;
  const supertokensAuthenticated = !session.loading && !!session.userId;

  const value = useMemo<IMe>(
    () => ({
      me: supertokensAuthenticated ? me : undefined,
      isAuthenticated: supertokensAuthenticated && !!data,
      isOnboarding: supertokensAuthenticated && !me,
      adminLoggedInAsUser,
      refreshMe: () => {
        refetch();
      },
      authenticate: (redirectToPath) => {
        if (redirectToPath) {
          navigate(`/auth/?redirectToPath=${redirectToPath}`);
        } else {
          navigate("/auth");
        }
      },
      logOut: async () => {
        try {
          await signOut(); // invalidates session on backend
          navigate("/auth");
        } catch (error) {
          showToast("Error", error, "error");
        }
      },
      adminLogInAsUser: (user) => {
        setAdminLoggedInAsUser(user);
        window.location.reload();
      },
      adminLogOutAsUser: () => {
        deleteAdminLoggedInAsUser();
        window.location.reload();
      },
    }),
    [
      me,
      adminLoggedInAsUser,
      refetch,
      setAdminLoggedInAsUser,
      deleteAdminLoggedInAsUser,
    ],
  );

  return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
};

export const useMe = () => {
  const context = useContext(MeContext);

  if (!context) {
    throw new Error("useMe must be used within a MeProvider");
  }

  return context;
};
