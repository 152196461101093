/* Je vous salue, Marie pleine de grâce le Seigneur est avec vous. Vous êtes bénie entre toutes les femmes 
et Jésus le fruit de vos entrailles, est béni. Sainte Marie, Mère de Dieu, priez pour nous pauvres pécheurs
maintenant et à l’heure de notre mort*/

// Has the Avatar scroll thing on the top

import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Stack,
  Text,
  WrapItem,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsCheck2All } from "react-icons/bs";
import { Wrap } from '@chakra-ui/react';
import { useMe } from "../providers/me-provider";
import { useSearchParams } from "react-router-dom";

const Conversation = ({ conversation, isOnline }) => {
  const user = conversation.otherParticipants[0];
  const { me: currentUser } = useMe();
  const lastMessage = conversation.lastMessage;
  const colorMode = useColorMode();
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedConversationId = searchParams.get("id");

  return (
    <Flex
      gap={4}
      alignItems={"center"}
      p={"1"}
      _hover={{
        cursor: "pointer",
        bg: useColorModeValue("gray.600", "gray.dark"),
        color: "white",
      }}
      onClick={() => setSearchParams({ id: conversation.id })}
      bg={
        selectedConversationId === conversation.id
          ? colorMode === "light"
            ? "gray.600"
            : "gray.dark"
          : ""
      }
      borderRadius={"md"}
    >
      <Wrap spacing={6}>
        <WrapItem>
          <Avatar
            size={{ base: "xs", sm: "sm", md: "md" }}
            src={user.avatarAsset?.url}
            name={user.username}
          >
            {isOnline ? <AvatarBadge boxSize="1em" bg="green.500" /> : ""}
          </Avatar>
        </WrapItem>
      </Wrap>

      <Stack direction={"column"} fontSize={"sm"}>
        <Text
          fontWeight="700"
          display={"flex"}
          alignItems={"center"}
          fontFamily={"Orbitron, sans-serif"}
        >
          {user.username}
        </Text>
        <Text
          fontSize={"10px"}
          dispaly={"flex"}
          alignItems={"center"}
          gap={1}
          color={"gray.400"}
          fontFamily={"Orbitron, sans-serif"}
        >
          {currentUser.id === lastMessage?.userId ? (
            <Box color={lastMessage?.read ? "" : ""}>
              <BsCheck2All size={16} />
            </Box>
          ) : (
            ""
          )}
          {lastMessage?.text > 18
            ? lastMessage.text.slice(0, 18) + "..."
            : lastMessage?.text}
        </Text>
      </Stack>
    </Flex>
  );
};

export default Conversation;
