import * as Types from '../../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { PostCommentFragmentFragmentDoc } from './post-comment-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PostCommentsQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type PostCommentsQuery = { __typename?: 'Query', postComments: { __typename?: 'PostCommentConnection', pageInfo: { __typename?: 'LimitOffsetPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, limit: number, offset: number, totalEdges: number }, edges: Array<{ __typename?: 'PostCommentEdge', node: { __typename?: 'PostComment', id: string, canDelete: boolean, parentId?: string | null, text: string, createdAt: any, user: { __typename?: 'User', id: string, displayName: string, username: string, avatarAsset?: { __typename?: 'Asset', id: string, url: string } | null } } }> } };


export const PostCommentsDocument = gql`
    query PostComments($id: String!, $limit: Int, $offset: Int) {
  postComments(id: $id, limit: $limit, offset: $offset) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      limit
      offset
      totalEdges
    }
    edges {
      node {
        ...PostCommentFragment
      }
    }
  }
}
    ${PostCommentFragmentFragmentDoc}`;

/**
 * __usePostCommentsQuery__
 *
 * To run a query within a React component, call `usePostCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePostCommentsQuery(baseOptions: Apollo.QueryHookOptions<PostCommentsQuery, PostCommentsQueryVariables> & ({ variables: PostCommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostCommentsQuery, PostCommentsQueryVariables>(PostCommentsDocument, options);
      }
export function usePostCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostCommentsQuery, PostCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostCommentsQuery, PostCommentsQueryVariables>(PostCommentsDocument, options);
        }
export function usePostCommentsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PostCommentsQuery, PostCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PostCommentsQuery, PostCommentsQueryVariables>(PostCommentsDocument, options);
        }
export type PostCommentsQueryHookResult = ReturnType<typeof usePostCommentsQuery>;
export type PostCommentsLazyQueryHookResult = ReturnType<typeof usePostCommentsLazyQuery>;
export type PostCommentsSuspenseQueryHookResult = ReturnType<typeof usePostCommentsSuspenseQuery>;
export type PostCommentsQueryResult = Apollo.QueryResult<PostCommentsQuery, PostCommentsQueryVariables>;