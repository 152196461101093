import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessageSummaryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MessageSummaryQuery = { __typename?: 'Query', messageSummary: { __typename?: 'MessageSummary', id: string, hasNewMessages: boolean } };


export const MessageSummaryDocument = gql`
    query MessageSummary {
  messageSummary {
    id
    hasNewMessages
  }
}
    `;

/**
 * __useMessageSummaryQuery__
 *
 * To run a query within a React component, call `useMessageSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageSummaryQuery(baseOptions?: Apollo.QueryHookOptions<MessageSummaryQuery, MessageSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageSummaryQuery, MessageSummaryQueryVariables>(MessageSummaryDocument, options);
      }
export function useMessageSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageSummaryQuery, MessageSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageSummaryQuery, MessageSummaryQueryVariables>(MessageSummaryDocument, options);
        }
export function useMessageSummarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MessageSummaryQuery, MessageSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessageSummaryQuery, MessageSummaryQueryVariables>(MessageSummaryDocument, options);
        }
export type MessageSummaryQueryHookResult = ReturnType<typeof useMessageSummaryQuery>;
export type MessageSummaryLazyQueryHookResult = ReturnType<typeof useMessageSummaryLazyQuery>;
export type MessageSummarySuspenseQueryHookResult = ReturnType<typeof useMessageSummarySuspenseQuery>;
export type MessageSummaryQueryResult = Apollo.QueryResult<MessageSummaryQuery, MessageSummaryQueryVariables>;