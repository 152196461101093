import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { ConversationFragmentFragmentDoc } from './conversation-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConversationsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Array<Types.ConversationFilter> | Types.ConversationFilter>;
  query?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ConversationsQuery = { __typename?: 'Query', conversations: { __typename?: 'ConversationConnection', pageInfo: { __typename?: 'LimitOffsetPageInfo', totalEdges: number, offset: number, limit: number, hasPreviousPage: boolean, hasNextPage: boolean }, edges: Array<{ __typename?: 'ConversationEdge', node: { __typename?: 'Conversation', id: string, favorited: boolean, read: boolean, sortBy: any, otherParticipants: Array<{ __typename?: 'User', id: string, displayName: string, username: string, presence: Types.Presence, avatarAsset?: { __typename?: 'Asset', url: string, id: string } | null }>, lastMessage?: { __typename?: 'Message', id: string, text?: string | null, userId: string, createdAt: any, assets: Array<{ __typename?: 'Asset', id: string, url: string, type: Types.AssetType }> } | null } }> } };


export const ConversationsDocument = gql`
    query Conversations($filters: [ConversationFilter!], $query: String, $limit: Int, $offset: Int) {
  conversations(filters: $filters, query: $query, limit: $limit, offset: $offset) {
    pageInfo {
      totalEdges
      offset
      limit
      hasPreviousPage
      hasNextPage
    }
    edges {
      node {
        ...ConversationFragment
      }
    }
  }
}
    ${ConversationFragmentFragmentDoc}`;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useConversationsQuery(baseOptions?: Apollo.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
        }
export function useConversationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsSuspenseQueryHookResult = ReturnType<typeof useConversationsSuspenseQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;