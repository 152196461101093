import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyForFundingMutationVariables = Types.Exact<{
  input: Types.ApplyForFundingInput;
}>;


export type ApplyForFundingMutation = { __typename?: 'Mutation', id: string };


export const ApplyForFundingDocument = gql`
    mutation ApplyForFunding($input: ApplyForFundingInput!) {
  id: applyForFunding(input: $input)
}
    `;
export type ApplyForFundingMutationFn = Apollo.MutationFunction<ApplyForFundingMutation, ApplyForFundingMutationVariables>;

/**
 * __useApplyForFundingMutation__
 *
 * To run a mutation, you first call `useApplyForFundingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForFundingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForFundingMutation, { data, loading, error }] = useApplyForFundingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyForFundingMutation(baseOptions?: Apollo.MutationHookOptions<ApplyForFundingMutation, ApplyForFundingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyForFundingMutation, ApplyForFundingMutationVariables>(ApplyForFundingDocument, options);
      }
export type ApplyForFundingMutationHookResult = ReturnType<typeof useApplyForFundingMutation>;
export type ApplyForFundingMutationResult = Apollo.MutationResult<ApplyForFundingMutation>;
export type ApplyForFundingMutationOptions = Apollo.BaseMutationOptions<ApplyForFundingMutation, ApplyForFundingMutationVariables>;