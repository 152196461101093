import {
  OnboardingSceneProps as SceneProps,
  OnboardingViewModelProps,
} from "./props";
import { FieldValues } from "react-hook-form";
import { useRegisterMutation } from "./networking/__generated__/register-mutation.generated";
import { useEffect, useState } from "react";
import { MeDocument } from "../../providers/networking/__generated__/me-query.generated";
import { useNavigate } from "react-router-dom";

const useOnboardingViewModel: (
  props: SceneProps,
) => OnboardingViewModelProps = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [registerAPI, { error: registerError }] = useRegisterMutation();
  const [validationError, setValidationError] = useState<string>();
  const [networkError, setNetworkError] = useState<string>();
  const navigate = useNavigate();

  useEffect(() => {
    setNetworkError(registerError?.message);
  }, [registerError?.message]);

  return {
    validationError,
    loading: submitting,
    networkError,
    clearNetworkError: () => {
      setNetworkError(undefined);
    },
    submit: async (data: FieldValues) => {
      if (!data.username || !data.name) {
        return;
      }

      setSubmitting(true);

      try {
        await registerAPI({
          variables: {
            data: {
              username: data.username,
              displayName: data.name,
            },
          },
          refetchQueries: [
            {
              query: MeDocument,
            },
          ],
        });

        setSubmitting(false);
        navigate("/Rec");
      } catch (error) {
        setSubmitting(false);
        // do nothing, error is already handled
      }
    },
  };
};

export default useOnboardingViewModel;
