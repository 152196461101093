import { useEffect } from "react";
import { useParams } from "react-router-dom";
import useShowToast from "./useShowToast";
import { useUserByUsernameQuery } from "./networking/__generated__/user-by-username-query.generated";

const useGetUserProfile = () => {
  const { username } = useParams();
  const showToast = useShowToast();
  const { data, error, loading } = useUserByUsernameQuery({
    variables: { username },
  });

  useEffect(() => {
    if (!error) {
      return;
    }

    showToast("Error", error.message, "error");
  }, [error]);

  return { user: data?.user, loading };
};

export default useGetUserProfile;
