// This is the page that you click on it and zooms in
/* Gegrüßet seist du, Maria, voll der Gnade der Herr ist mit dir. Du bist gebenedeit unter den Frauen,
und gebenedeit ist die Frucht deines Leibes, Jesus Heilige Maria, Mutter Gottes bitte für uns Sünder
jetzt und in der Stunde unseres Todes Amen
*/

import {
  Avatar,
  Box,
  Flex,
  Image,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import useShowToast from "../hooks/useShowToast";
import { useParams, useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { DeleteIcon } from "@chakra-ui/icons";
import ReactPlayer from "react-player";
import PostFooter from "../components/PostFooter";
import Hls from "hls.js";
import { useMe } from "../providers/me-provider";
import { usePostQuery } from "./networking/__generated__/post-query.generated";
import { useDeletePostMutation } from "./networking/__generated__/delete-post-mutation.generated";
import { RemoteAssetViewer } from "../utils/use-upload-files";

const PostPage = () => {
  const showToast = useShowToast();
  const { pid } = useParams();
  const { me: currentUser } = useMe();
  const navigate = useNavigate();
  const [isSmallScreen] = useMediaQuery(
    "(min-width: 375px) and (min-height: 667px)",
  );
  const [isIpadEsq] = useMediaQuery(
    "(min-width: 768px) and (min-height: 1024px)",
  );

  const { data, loading, error } = usePostQuery({
    variables: {
      // @ts-ignore
      id: pid,
    },
    skip: !pid,
    fetchPolicy: "cache-first",
  });
  const post = data?.post;

  const [deletePostAPI, { error: deletePostError }] = useDeletePostMutation({
    variables: {
      // @ts-ignore
      id: pid,
    },
    refetchQueries: ["Timeline"],
  });

  useEffect(() => {
    if (!error && !deletePostError) {
      return;
    }

    showToast("Error", error?.message ?? deletePostError?.message, "error");
  }, [error]);

  const handleDeletePost = async () => {
    try {
      if (!window.confirm("Are you sure you want to delete this post?")) return;
      await deletePostAPI();
      showToast("Success", "Post deleted", "success");
      navigate(`/${post.user.username}`);
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  if (loading) return;
  <Flex>
    <Spinner size="xl" />
  </Flex>;

  if (!post) return null;

  const asset = post.assets.length ? post.assets[0] : null;

  return (
    <>
      <Flex marginLeft={isIpadEsq ? "-140" : isSmallScreen ? "-70" : "auto"}>
        <Flex w={"full"} alignItems={"center"} gap={3}>
          <Avatar
            src={post.user.avatarAsset?.url}
            size={"md"}
            name="CPN"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/${post.user.username}`);
            }}
          />
          <Flex>
            <Text
              fontSize={"sm"}
              fontWeight={"bold"}
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              {post.user.username}
            </Text>
          </Flex>
        </Flex>
        <Flex gap={4} alignItems={"center"}>
          <Text fontSize={"sm"} color={"gray.light"}></Text>
          <Flex gap={4} alignItems={"center"}>
            <Text
              fontSize={"xs"}
              width={36}
              textAlign={"right"}
              color={"rgb(135, 206, 250)"}
              fontFamily={"Orbitron, sans-serif"}
            >
              {formatDistanceToNow(new Date(post.scheduledAt))} ago
            </Text>
            {currentUser?.id === post.user.id && (
              <DeleteIcon
                size={"20"}
                cursor={"pointer"}
                onClick={handleDeletePost}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Text
        my={3}
        color="white"
        fontFamily={"Orbitron, sans-serif"}
        marginLeft={isIpadEsq ? "-20" : isSmallScreen ? "-30" : "auto"}
      >
        {post.text}
      </Text>
      {asset && (
        <Box
          borderRadius={6}
          overflow={"hidden"}
          border={"1px solid white"}
          borderColor={"gray.light"}
          marginLeft={isIpadEsq ? "-90px" : "auto"}
        >
          <RemoteAssetViewer asset={asset} />
        </Box>
      )}
      <Flex
        gap={3}
        my={3}
        marginLeft={isIpadEsq ? "-90px" : isSmallScreen ? "-30px" : "auto"}
      >
        <PostFooter post={post} />
      </Flex>
    </>
  );
};

export default PostPage;
