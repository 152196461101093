/* Je vous salue, Marie pleine de grâce le Seigneur est avec vous. Vous êtes bénie entre toutes les femmes 
et Jésus le fruit de vos entrailles, est béni. Sainte Marie, Mère de Dieu, priez pour nous pauvres pécheurs
maintenant et à l’heure de notre mort*/

import { Avatar, Box, Button, Flex, Grid, Spacer, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { IoIosAddCircleOutline } from "react-icons/io";
import useToggleFollowUser from "../../hooks/useToggleFollowUser";

const SuggestedUser = ({ user, onButtonClick }) => {
  const { toggleFollowUser, isFollowingUser } = useToggleFollowUser({
    userId: user.id,
  });
  return (
    <Grid templateColumns="1fr auto" alignItems="center">
      <Box as={Link} to={`/${user.username}`} mt={5}>
        <Avatar src={user.avatarAsset?.url} name={user.displayName} />
        <Box>
          <Text
            fontSize={"sm"}
            fontWeight={"bold"}
            fontFamily={"Orbitron, sans-serif"}
            color="rgb(135,206,250)"
          >
            {user.username}
          </Text>
          <Text
            fontSize={"sm"}
            fontFamily={"Orbitron, sans-serif"}
            color="rgb(135,206,250)"
          >
            {user.displayName}
          </Text>
        </Box>
      </Box>
      <Box>
        <IoIosAddCircleOutline
          size={"1.5em"} // Set this to the size you want
          color={isFollowingUser ? "#FF6EC7" : "white"}
          onClick={() => {
            toggleFollowUser();
            onButtonClick();
          }}
          _hover={{
            color: "blue.500", // Change this to the color you want on hover
          }}
          style={{ cursor: "pointer" }}
        />
      </Box>
    </Grid>
  );
};

export default SuggestedUser;
