/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

import { useMediaQuery } from "@chakra-ui/react";
import { Box, Flex, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { GiHexagonalNut } from "react-icons/gi";
import { FaGlobeAmericas } from "react-icons/fa";
import Search from "./Search";
import { RxAvatar } from "react-icons/rx";
import { Button } from "@chakra-ui/react";
import { AiFillHome } from "react-icons/ai";
import { MdOutlineSettings } from "react-icons/md";
import { IoIosChatboxes } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { GrScorecard } from "react-icons/gr";
import { useMe } from "../providers/me-provider";

const SidebarItems = ({ closeSidebar }) => {
  const { me: user, logOut } = useMe();
  const [isIpadEsq] = useMediaQuery(
    "(min-width: 768px) and (min-height: 1024px)",
  );

  return (
    <>
      <Link as={RouterLink} to="/home" mt={4} onClick={closeSidebar}>
        <Flex
          size={isIpadEsq ? "lg" : "md"}
          fontSize={isIpadEsq ? "1.5em" : "1em"}
          marginTop={isIpadEsq ? "20px" : "0"}
        >
          <AiFillHome size="1.5em" />
          <Text ml={2} fontFamily="'Orbitron', sans-serif">
            HomePage
          </Text>
        </Flex>
      </Link>

      <Flex
        alignItems="center"
        onClick={closeSidebar}
        size={isIpadEsq ? "lg" : "md"}
        fontSize={isIpadEsq ? "1.5em" : "1em"}
        marginTop={isIpadEsq ? "20px" : "0"}
      >
        <CiSearch size={25} />
        <Box ml={5}>
          <Search />
        </Box>
      </Flex>

      <Link as={RouterLink} to="/" onClick={closeSidebar}>
        <Flex
          alignItems="center"
          size={isIpadEsq ? "lg" : "md"}
          fontSize={isIpadEsq ? "1.5em" : "1em"}
          marginTop={isIpadEsq ? "20px" : "0"}
        >
          <FaGlobeAmericas size="1.5em" />
          <Text ml={2} fontFamily="'Orbitron', sans-serif">
            Global
          </Text>
        </Flex>
      </Link>

      {/*
      <Flex>
      <GiHoneyJar size="1.5em"/> 
      <Text ml={2} fontFamily="'Orbitron', sans-serif">QBs</Text>
      </Flex>
  */}

      <Link as={RouterLink} to={`/Hivemenu`} onClick={closeSidebar}>
        <Flex
          size={isIpadEsq ? "lg" : "md"}
          fontSize={isIpadEsq ? "1.5em" : "1em"}
          marginTop={isIpadEsq ? "20px" : "0"}
        >
          <GiHexagonalNut size="2em" color="yellow" />
          <Text ml={2} color="yellow" fontFamily="'Orbitron', sans-serif">
            HiveMind
          </Text>
        </Flex>
      </Link>

      <Link as={RouterLink} to={`/chat`} onClick={closeSidebar}>
        <Flex
          alignItems="center"
          size={isIpadEsq ? "lg" : "md"}
          fontSize={isIpadEsq ? "1.5em" : "1em"}
          marginTop={isIpadEsq ? "20px" : "0"}
        >
          <IoIosChatboxes size="1.5em" />
          <Text ml={2} fontFamily="'Orbitron', sans-serif">
            Chat
          </Text>
        </Flex>
      </Link>

      <Link as={RouterLink} to={`/scholarscores`} onClick={closeSidebar}>
        <Flex
          alignItems="center"
          size={isIpadEsq ? "lg" : "md"}
          fontSize={isIpadEsq ? "1.5em" : "1em"}
          marginTop={isIpadEsq ? "20px" : "0"}
        >
          <GrScorecard size="1.5em" color="#00FF00" />
          <Text ml={2} fontFamily="'Orbitron', sans-serif">
            Compete
          </Text>
        </Flex>
      </Link>

      {user ? (
        <Link as={RouterLink} to={`/${user.username}`} onClick={closeSidebar}>
          <Flex
            alignItems="center"
            size={isIpadEsq ? "lg" : "md"}
            fontSize={isIpadEsq ? "1.5em" : "1em"}
            marginTop={isIpadEsq ? "20px" : "0"}
          >
            <RxAvatar size="1.5em" />
            <Text ml={2} fontFamily="'Orbitron', sans-serif">
              User
            </Text>
          </Flex>
        </Link>
      ) : (
        <Link as={RouterLink} to="/auth" onClick={closeSidebar}>
          <Flex
            alignItems="center"
            size={isIpadEsq ? "lg" : "md"}
            fontSize={isIpadEsq ? "1.5em" : "1em"}
            marginTop={isIpadEsq ? "20px" : "0"}
          >
            <RxAvatar size="1.5em" />
            <Text ml={2} fontFamily="'Orbitron', sans-serif">
              Login
            </Text>
          </Flex>
        </Link>
      )}

      <Link as={RouterLink} to={`/settings`} onClick={closeSidebar}>
        <Flex
          alignItems="center"
          size={isIpadEsq ? "lg" : "md"}
          fontSize={isIpadEsq ? "1.5em" : "1em"}
          marginTop={isIpadEsq ? "20px" : "0"}
        >
          <MdOutlineSettings size="1.5em" />
          <Text ml={2} fontFamily="'Orbitron', sans-serif">
            Settings
          </Text>
        </Flex>
      </Link>

      {/* 
<Link as={RouterLink} to={`/funding`} onClick={closeSidebar}>
<Flex alignItems='center'
  size={isIpadEsq ? "lg" : "md"} 
  fontSize={isIpadEsq ? "1.5em" : "1em"}
  marginTop={isIpadEsq ? "20px" : "0"}>
<RiMoneyDollarBoxLine size="1.5em"/>
<Text ml={2} 
fontFamily="'Orbitron', sans-serif"
>Funding</Text>
</Flex >
</Link>




*/}

      <Button
        size={isIpadEsq ? "lg" : "md"}
        fontSize={isIpadEsq ? "1.5em" : "1em"}
        onClick={logOut}
        display="block"
        variant={"ghost"}
        _hover={{ bg: "transparent" }}
        fontFamily={"'Orbitron', sans-serif"}
        color="orange"
        border="2px solid orange"
        marginTop={isIpadEsq ? "5" : "0"}
      >
        Logout
      </Button>
    </>
  );
};

export default SidebarItems;
