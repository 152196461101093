/* Ave Maria gratia plena, Dominus tecum, benedicta tu in mulieribus et benedictus fructus 
ventris tui Iesu, Santa Maria, mater Dei, ora pro nobis peccatoribus, nunc et in ora mortis nostrae */

import { AddIcon } from '@chakra-ui/icons'
import { Box, Button, CloseButton, Flex, FormControl, Image, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader,
     ModalOverlay, Spinner, Text, Textarea, useDisclosure, 
     useMediaQuery} from '@chakra-ui/react'
import React, { useRef, useState, useEffect } from "react";
import useShowToast from "../hooks/useShowToast";
import ReactPlayer from "react-player";
import { LiaCameraRetroSolid } from "react-icons/lia";
import Autosuggest from "react-autosuggest";
import { useMe } from "../providers/me-provider";
import { useCreatePostMutation } from "./networking/__generated__/create-post-mutation.generated";
import sanitizeHtml from "sanitize-html";
import { useTagsQuery } from "./networking/__generated__/tags-query.generated";
import useUploadFiles, {
  supportedMediaTypes,
  LocalFileViewer,
} from "../utils/use-upload-files";

const MAX_CHAR = 500;

const CreatePost = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [postText, setPostText] = useState("");
  const [remainingChar, setRemainingChar] = useState(MAX_CHAR);
  const { me: user } = useMe();
  const showToast = useShowToast();
  const [selectedTagNames, setSelectedTagNames] = useState([]);
  const [value, setValue] = useState("");
  const [isSmallScreen] = useMediaQuery(
    "(min-width: 375px) and (min-height: 667px)",
  );

  const { data: tagsData, error: tagsError } = useTagsQuery({
    variables: {
      limit: 1000,
    },
    fetchPolicy: "cache-first",
  });
  const allTags = tagsData?.tags.edges.map(({ node }) => node) || [];

  const [suggestions, setSuggestions] = useState([]);

  const [loading, setLoading] = React.useState(false);
  const [asset, setAsset] = useState();
  const [localFile, setLocalFile] = useState();
  const imageRef = useRef(null);

  const [progress, setProgress] = useState(0);
  const { upload } = useUploadFiles();

  const [createPostAPI] = useCreatePostMutation();

  const handleTextChange = (e) => {
    const inputText = e.target.value;

    if (inputText.length > MAX_CHAR) {
      const truncatedText = inputText.slice(0, MAX_CHAR);
      setPostText(truncatedText);
      setRemainingChar(0);
    } else {
      setPostText(inputText);
      setRemainingChar(MAX_CHAR - inputText.length);
    }
  };

  useEffect(() => {
    if (!tagsError) {
      return;
    }

    showToast("Error", tagsError.message, "error");
  }, [tagsError]);

  const handleCreatePost = async () => {
    setLoading(true);
    console.log("Current user ID:", user.id);

    if (!user.id) {
      showToast("Error", "User not identified", "error");
      setLoading(false);
      return;
    }

    const selectedTagIds = selectedTagNames.reduce((acc, tagName) => {
      const tag = allTags.find((tag) => tag.name === tagName);

      if (tag) {
        acc.push(tag.id);
      }

      return acc;
    }, []);

    try {
      await createPostAPI({
        variables: {
          input: {
            text: sanitizeHtml(postText),
            scheduledAt: new Date(),
            tagIds: selectedTagIds,
            assetIds: asset ? [asset.id] : [],
          },
        },
        refetchQueries: ["Timeline"],
      });
    } catch (error) {
      console.error(error);
      showToast("Error", "An unexpected error occurred", "error");
    }

    onClose();
    setPostText("");
    setLocalFile(undefined);
    setAsset(undefined);
    setLoading(false);
  };

  const getSuggestions = (value) => {
    const tags = value.split(",");

    const lastTag = tags[tags.length - 1].trim();

    const inputValue = lastTag.toLowerCase();

    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : allTags.filter((suggestion) =>
          suggestion.name.toLowerCase().includes(inputValue),
        );
  };

  const getSuggestionValue = (suggestion) => {
    const tagsArray = value.split(",");

    tagsArray[tagsArray.length - 1] = suggestion.name;

    setSelectedTagNames(tagsArray);
    return `${tagsArray.join(", ")}, `;
  };
  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  const inputProps = {
    placeholder: "Tags",
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
    },
    style: {
      fontFamily: "Orbitron, sans-serif",
      backgroundColor: "black",
      color: "white",
      border: "1px solid white",
      borderRadius: "5px",
      width: "400px",
      height: "40px",
    },
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    console.log("onSuggestionsFetchRequested called with value:", value);
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = (inputProps) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ fontSize: "24px", marginRight: "10px" }}>#</span>
      <input {...inputProps} />
    </div>
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCreatePost();
    }
  };

  const handleMediaChange = async (event) => {
    const fileList = event.target.files;

    if (!fileList) {
      return;
    }

    const files = Array.from(fileList);
    const file = files[0];

    setAsset(undefined);
    setProgress(0);
    setLocalFile(file);
    setLoading(true);

    try {
      const asset = await upload(file, setProgress);
      setAsset(asset);
    } catch (error) {
      showToast("Error", error.message, "error");
      setLocalFile(undefined);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        position={"fixed"}
        bottom={10}
        right={isSmallScreen ? "50" : "5"}
        bg="gray.700"
        onClick={onOpen}
        size={{ base: "sm", sm: "md", md: "lg" }}
        border="2px"
        borderColor="rgb(135, 206, 250)"
      >
        <AddIcon color="rgb(135, 206, 250)" />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent backgroundColor="black" border="1px" borderColor="#white">
          <ModalHeader
            textAlign="center"
            fontFamily={"Orbitron, sans-serif"}
            color="rgb(135,206,250)"
          >
            Create Post
          </ModalHeader>
          <ModalBody pb={6}>
            <FormControl>
              <Textarea
                placeholder="Post content goes here"
                fontFamily={"Orbitron, sans-serif"}
                color="rgb(135,206,250)"
                backgroundColor="black"
                onChange={handleTextChange}
                onKeyDown={handleKeyDown}
                value={postText}
              />
              <Box marginTop="4">
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  renderInputComponent={renderInputComponent}
                />
              </Box>

              <Text
                fontSize="xs"
                fontWeight="bold"
                textAlign={"right"}
                m={"1"}
                color={"gray.800"}
              >
                {remainingChar}/{MAX_CHAR}
              </Text>

              <div>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "10vh",
                      transform: "translateY(20px)",
                    }}
                  >
                    <Text
                      fontFamily={"Orbitron, sans-serif"}
                      color="rgb(135,206,250)"
                      style={{ marginBottom: "20px" }}
                    >
                      Uploading... {(progress * 100).toFixed(2)}%
                    </Text>
                    <Spinner />
                  </div>
                ) : (
                  <Input
                    accept={supportedMediaTypes.join(", ")}
                    type="file"
                    hidden
                    ref={imageRef}
                    onChange={handleMediaChange}
                  />
                )}
              </div>
              <LiaCameraRetroSolid
                style={{ marginLeft: "5px", cursor: "pointer" }}
                size={24}
                color="#FF6EC7"
                onClick={() => imageRef.current.click()}
              />
            </FormControl>

            {localFile && (
              <Flex mt={5} w={"full"} position={"relative"}>
                <LocalFileViewer localFile={localFile} />
                <CloseButton
                  onClick={() => {
                    setAsset(undefined);
                    setLocalFile(undefined);
                  }}
                  bg={"gray.800"}
                  position={"absolute"}
                  top={2}
                  right={2}
                />
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={handleCreatePost}
              isLoading={loading}
              fontFamily={"Orbitron, sans-serif"}
              color="rgb(135,206,250)"
            >
              Post
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreatePost
