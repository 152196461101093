/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { Box, Button, Flex, HStack, Spinner, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useShowToast from "../hooks/useShowToast";
import Post from "../components/Post";
import { useMediaQuery } from "@chakra-ui/react";
import { useTimelineQuery } from "./networking/__generated__/timeline-query.generated";
import keyBy from "lodash/keyBy";

const LIMIT = 20;

const GlobalPage = () => {
  const showToast = useShowToast();
  const [hasMore, setHasMore] = useState(true);

  const { data, error, fetchMore } = useTimelineQuery({
    variables: {
      limit: LIMIT,
    },
  });
  const posts = data?.timeline.edges.map(({ node }) => node) || [];

  const firstColumnPosts = posts.filter((_, index) => index % 3 === 0);
  const secondColumnPosts = posts.filter((_, index) => index % 3 === 1);
  const thirdColumnPosts = posts.filter((_, index) => index % 3 === 2);

  const [isLessThan375] = useMediaQuery(
    "(min-width: 375px) and (min-height: 667px)",
  );
  const [isIphone] = useMediaQuery([
    "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)",
  ]);
  const [isIpadEsq] = useMediaQuery(
    "(min-width: 768px) and (min-height: 1024px)",
  );
  const [isMac] = useMediaQuery("(min-width: 1280px) and (min-height: 800px)");

  useEffect(() => {
    if (!error) {
      return;
    }

    showToast("Error", error.message, "error");
  }, [error]);

  const onLoadMoreClick = async () => {
    try {
      const fetchMoreData = await fetchMore({
        variables: {
          offset: data.timeline.edges.length,
          limit: LIMIT,
        },
        updateQuery: (previous, { fetchMoreResult }) => {
          const incomingTimelineEdges = fetchMoreResult.timeline.edges;
          const incomingTimelineEdgesById = keyBy(
            incomingTimelineEdges,
            (edge) => edge.node.id,
          );

          // Just in case, let's make sure there are no duplicates
          const previousTimelineEdges = previous.timeline.edges.filter(
            (edge) => !incomingTimelineEdgesById[edge.node.id],
          );

          return {
            timeline: {
              ...previous.timeline,
              pageInfo: fetchMoreResult.timeline.pageInfo,
              edges: [...previousTimelineEdges, ...incomingTimelineEdges],
            },
          };
        },
      });

      if (fetchMoreData.data.timeline.edges.length < LIMIT) {
        setHasMore(false);
      }
    } catch (error) {
      // already handled above
    }
  };

  console.log({ isMac, isIpadEsq, isIphone, isLessThan375 });

  return (
    <Flex marginLeft="-70px">
      {!data ? (
        <Spinner size="xl" ml="225px" />
      ) : (
        <VStack pb={4}>
          {isLessThan375 || isIphone ? (
            <VStack spacing={4} width="100%">
              {posts.map((post, index) => (
                <Box
                  key={index}
                  bg={index % 2 === 0 ? "gray.800" : "black"}
                  borderRadius="lg"
                >
                  <Post post={post} key={post.id} />
                </Box>
              ))}
            </VStack>
          ) : isIpadEsq ? (
            <>
              <VStack spacing={4} width="20vw" ml="-5">
                {firstColumnPosts.map((post, index) => (
                  <Box
                    key={index}
                    bg={index % 2 === 0 ? "gray.800" : "black"}
                    borderRadius="lg"
                  >
                    <Post
                      post={post}
                      key={post.id}
                      style={{ fontSize: "0.6rem" }}
                    />
                  </Box>
                ))}
              </VStack>

              <VStack spacing={4} width="20vw" ml="170">
                {secondColumnPosts.map((post, index) => (
                  <Box
                    key={index}
                    bg={index % 2 === 0 ? "gray.800" : "black"}
                    borderRadius="lg"
                  >
                    <Post
                      post={post}
                      key={post.id}
                      style={{ fontSize: "0.6rem" }}
                    />
                  </Box>
                ))}
              </VStack>
            </>
          ) : isMac ? (
            <>
              <VStack spacing={4} width="20%" marginRight={30} marginLeft={-1}>
                {firstColumnPosts.map((post, index) => (
                  <Box
                    key={index}
                    bg={index % 2 === 0 ? "gray.800" : "black"}
                    borderRadius="lg"
                  >
                    <Post
                      post={post}
                      key={post.id}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </Box>
                ))}
              </VStack>

              <VStack spacing={4} width="20%" marginRight={62} marginLeft={40}>
                {secondColumnPosts.map((post, index) => (
                  <Box
                    key={index}
                    bg={index % 2 === 0 ? "gray.800" : "black"}
                    borderRadius="lg"
                  >
                    <Post
                      post={post}
                      key={post.id}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </Box>
                ))}
              </VStack>

              <VStack spacing={4} width="20%" marginLeft={120}>
                {thirdColumnPosts.map((post, index) => (
                  <Box
                    key={index}
                    bg={index % 2 === 0 ? "gray.800" : "black"}
                    borderRadius="lg"
                  >
                    <Post
                      post={post}
                      key={post.id}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </Box>
                ))}
              </VStack>
            </>
          ) : (
            <>
              <VStack spacing={4} width="20%" marginRight={30} marginLeft={-1}>
                {firstColumnPosts.map((post, index) => (
                  <Box
                    key={index}
                    bg={index % 2 === 0 ? "gray.800" : "black"}
                    borderRadius="lg"
                  >
                    <Post
                      post={post}
                      key={post.id}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </Box>
                ))}
              </VStack>

              <VStack spacing={4} width="20%" marginRight={62} marginLeft={40}>
                {secondColumnPosts.map((post, index) => (
                  <Box
                    key={index}
                    bg={index % 2 === 0 ? "gray.800" : "black"}
                    borderRadius="lg"
                  >
                    <Post
                      post={post}
                      key={post.id}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </Box>
                ))}
              </VStack>

              <VStack spacing={4} width="20%" marginLeft={120}>
                {thirdColumnPosts.map((post, index) => (
                  <Box
                    key={index}
                    bg={index % 2 === 0 ? "gray.800" : "black"}
                    borderRadius="lg"
                  >
                    <Post
                      post={post}
                      key={post.id}
                      style={{ fontSize: "0.8rem" }}
                    />
                  </Box>
                ))}
              </VStack>
            </>
          )}
          {hasMore && <Button onClick={onLoadMoreClick}>Load More</Button>}
        </VStack>
      )}
    </Flex>
  );
};
export default GlobalPage;