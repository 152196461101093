import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkConversationReadMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type MarkConversationReadMutation = { __typename?: 'Mutation', conversation?: { __typename?: 'Conversation', id: string, read: boolean } | null };


export const MarkConversationReadDocument = gql`
    mutation MarkConversationRead($id: String!) {
  conversation: updateConversation(id: $id, input: {read: true}) {
    id
    read
  }
}
    `;
export type MarkConversationReadMutationFn = Apollo.MutationFunction<MarkConversationReadMutation, MarkConversationReadMutationVariables>;

/**
 * __useMarkConversationReadMutation__
 *
 * To run a mutation, you first call `useMarkConversationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkConversationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markConversationReadMutation, { data, loading, error }] = useMarkConversationReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkConversationReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkConversationReadMutation, MarkConversationReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkConversationReadMutation, MarkConversationReadMutationVariables>(MarkConversationReadDocument, options);
      }
export type MarkConversationReadMutationHookResult = ReturnType<typeof useMarkConversationReadMutation>;
export type MarkConversationReadMutationResult = Apollo.MutationResult<MarkConversationReadMutation>;
export type MarkConversationReadMutationOptions = Apollo.BaseMutationOptions<MarkConversationReadMutation, MarkConversationReadMutationVariables>;