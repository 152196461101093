import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { PostFragmentFragmentDoc } from './post-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TimelineQueryVariables = Types.Exact<{
  followingOnly?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  userId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  tagId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type TimelineQuery = { __typename?: 'Query', timeline: { __typename?: 'TimelineConnection', pageInfo: { __typename?: 'LimitOffsetPageInfo', hasNextPage: boolean, hasPreviousPage: boolean, limit: number, offset: number, totalEdges: number }, edges: Array<{ __typename?: 'TimelineEdge', node: { __typename?: 'Post', id: string, text?: string | null, scheduledAt?: any | null, liked: boolean, likeCount: number, commentCount: number, user: { __typename?: 'User', id: string, username: string, displayName: string, avatarAsset?: { __typename?: 'Asset', id: string, url: string } | null }, assets: Array<{ __typename?: 'Asset', id: string, type: Types.AssetType, url: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> } }> } };


export const TimelineDocument = gql`
    query Timeline($followingOnly: Boolean, $userId: String, $tagId: String, $limit: Int, $offset: Int) {
  timeline(
    followingOnly: $followingOnly
    userId: $userId
    tagId: $tagId
    limit: $limit
    offset: $offset
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      limit
      offset
      totalEdges
    }
    edges {
      node {
        ...PostFragment
      }
    }
  }
}
    ${PostFragmentFragmentDoc}`;

/**
 * __useTimelineQuery__
 *
 * To run a query within a React component, call `useTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimelineQuery({
 *   variables: {
 *      followingOnly: // value for 'followingOnly'
 *      userId: // value for 'userId'
 *      tagId: // value for 'tagId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useTimelineQuery(baseOptions?: Apollo.QueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
      }
export function useTimelineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
        }
export function useTimelineSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TimelineQuery, TimelineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TimelineQuery, TimelineQueryVariables>(TimelineDocument, options);
        }
export type TimelineQueryHookResult = ReturnType<typeof useTimelineQuery>;
export type TimelineLazyQueryHookResult = ReturnType<typeof useTimelineLazyQuery>;
export type TimelineSuspenseQueryHookResult = ReturnType<typeof useTimelineSuspenseQuery>;
export type TimelineQueryResult = Apollo.QueryResult<TimelineQuery, TimelineQueryVariables>;