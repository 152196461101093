import { ApolloCache } from "@apollo/client";
import { MessageFragmentFragment } from "../pages/networking/__generated__/message-fragment.generated";
import {
  ConversationMessagesDocument,
  ConversationMessagesQuery,
  ConversationMessagesQueryVariables,
} from "../pages/networking/__generated__/conversation-messages-query.generated";
import {
  ConversationsDocument,
  ConversationsQuery,
  ConversationsQueryVariables,
} from "../pages/networking/__generated__/conversations-query.generated";
import { ConversationFragmentFragment } from "../pages/networking/__generated__/conversation-fragment.generated";

export const addNewInboundMessageToCache = (
  cache: ApolloCache<any>,
  message: MessageFragmentFragment,
  conversation: ConversationFragmentFragment,
  searchParams: URLSearchParams,
) => {
  const searchQuery = searchParams.get("search")?.toString() ?? undefined;

  cache.updateQuery<
    ConversationMessagesQuery,
    ConversationMessagesQueryVariables
  >(
    {
      query: ConversationMessagesDocument,
      variables: {
        id: conversation.id,
      },
    },
    (data) => {
      if (!data) {
        return data;
      }

      return {
        conversationMessages: {
          ...data.conversationMessages,
          pageInfo: {
            ...data.conversationMessages.pageInfo,
            totalEdges: data.conversationMessages.pageInfo.totalEdges + 1,
          },
          edges: [
            {
              __typename: "ConversationMessagesEdge",
              node: message,
            },
            ...data.conversationMessages.edges,
          ],
        },
      };
    },
  );

  cache.updateQuery<ConversationsQuery, ConversationsQueryVariables>(
    {
      query: ConversationsDocument,
      variables: {
        query: searchQuery,
      },
    },
    (data) => {
      if (!data) {
        return data;
      }

      const conversations = data.conversations.edges.map((edge) => edge.node);
      const conversationIndex = conversations.findIndex(
        (c) => c.id === conversation.id,
      );

      if (conversationIndex !== -1) {
        conversations.splice(conversationIndex, 1);
      }

      conversations.unshift(conversation);

      return {
        conversations: {
          ...data.conversations,
          edges: conversations.map((c) => ({
            __typename: "ConversationEdge",
            node:
              c.id === conversation.id
                ? {
                    ...c,
                    lastMessage: message,
                    sortBy: message.createdAt,
                    read: false,
                  }
                : {
                    ...c,
                  },
          })),
        },
      };
    },
  );
};

export const addNewMessageToCache = (
  cache: ApolloCache<any>,
  conversationId: string,
  message: MessageFragmentFragment,
  searchParams: URLSearchParams,
) => {
  const searchQuery = searchParams.get("search")?.toString() ?? undefined;

  cache.updateQuery<
    ConversationMessagesQuery,
    ConversationMessagesQueryVariables
  >(
    {
      query: ConversationMessagesDocument,
      variables: {
        id: conversationId,
      },
    },
    (data) => {
      if (!data) {
        return data;
      }

      return {
        conversationMessages: {
          ...data.conversationMessages,
          pageInfo: {
            ...data.conversationMessages.pageInfo,
            totalEdges: data.conversationMessages.pageInfo.totalEdges + 1,
          },
          edges: [
            {
              __typename: "ConversationMessagesEdge",
              node: message,
            },
            ...data.conversationMessages.edges,
          ],
        },
      };
    },
  );

  cache.updateQuery<ConversationsQuery, ConversationsQueryVariables>(
    {
      query: ConversationsDocument,
      variables: {
        query: searchQuery,
      },
    },
    (data) => {
      if (!data) {
        return data;
      }

      const conversations = data.conversations.edges.map((edge) => edge.node);
      const conversationIndex = conversations.findIndex(
        (c) => c.id === conversationId,
      );

      if (conversationIndex !== -1) {
        const conversation = conversations[conversationIndex];

        conversations.splice(conversationIndex, 1);
        conversations.unshift(conversation);

        return {
          conversations: {
            ...data.conversations,
            edges: conversations.map((conversation) => ({
              __typename: "ConversationEdge",
              node:
                conversation.id === conversationId
                  ? {
                      ...conversation,
                      sortBy: message.createdAt,
                      lastMessage: message,
                    }
                  : {
                      ...conversation,
                    },
            })),
          },
        };
      }
    },
  );
};
