import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSkillsMutationVariables = Types.Exact<{
  skills: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type UpdateSkillsMutation = { __typename?: 'Mutation', updateSelectedUser: { __typename?: 'User', id: string, skills: Array<string> } };


export const UpdateSkillsDocument = gql`
    mutation UpdateSkills($skills: [String!]!) {
  updateSelectedUser(input: {skills: $skills}) {
    id
    skills
  }
}
    `;
export type UpdateSkillsMutationFn = Apollo.MutationFunction<UpdateSkillsMutation, UpdateSkillsMutationVariables>;

/**
 * __useUpdateSkillsMutation__
 *
 * To run a mutation, you first call `useUpdateSkillsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSkillsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSkillsMutation, { data, loading, error }] = useUpdateSkillsMutation({
 *   variables: {
 *      skills: // value for 'skills'
 *   },
 * });
 */
export function useUpdateSkillsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSkillsMutation, UpdateSkillsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSkillsMutation, UpdateSkillsMutationVariables>(UpdateSkillsDocument, options);
      }
export type UpdateSkillsMutationHookResult = ReturnType<typeof useUpdateSkillsMutation>;
export type UpdateSkillsMutationResult = Apollo.MutationResult<UpdateSkillsMutation>;
export type UpdateSkillsMutationOptions = Apollo.BaseMutationOptions<UpdateSkillsMutation, UpdateSkillsMutationVariables>;