//this whole thing is filler. Maria was right in that she told me that the like feture should be something more accesible since it is better for the algorithm and funner for the user. 
//We should revisit the like feature to make it like insta where you can just tap twice and you are set 

/* Je vous salue, Marie pleine de grâce le Seigneur est avec vous. Vous êtes bénie entre toutes les femmes 
et Jésus le fruit de vos entrailles, est béni. Sainte Marie, Mère de Dieu, priez pour nous pauvres pécheurs
maintenant et à l’heure de notre mort*/

import { BiLike as BiLikeIcon } from "react-icons/bi";
import { Box, Flex, Tooltip } from "@chakra-ui/react"
import useToggleLikePost from "../../hooks/useToggleLikePost";

const Like = ({ post }) => {
  const toggleLikePost = useToggleLikePost({ post });

  return (
    <Tooltip
      hasArrow
      label={"Like"}
      placement="right"
      ml={1}
      openDelay={500}
      display={{ base: "block", md: "none" }}
      fontFamily={"'Orbitron', sans-serif"}
    >
      <Flex
        alignItems={"center"}
        gap={4}
        borderRadius={6}
        p={2}
        w={{ base: 10, md: "full" }}
        justifyContent={{ base: "center", md: "flex-start" }}
        onClick={toggleLikePost}
      >
        <BiLikeIcon size={20} color={post.liked ? "#00FF00" : "white"} />
        <Box
          display={{ base: "none", md: "block" }}
          fontFamily={"'Orbitron', sans-serif"}
          color="#8d8d8d"
          css={{
            textShadow:
              "0 0 2px yellow.400, 0 0 3px yellow.400, 0 0 4px yellow.400",
            mixBlendMode: "difference",
          }}
        >
          {post.likeCount} {post.likeCount === 1 ? "Like" : "Likes"}
        </Box>
      </Flex>
    </Tooltip>
  );
};

export default Like;

/* 

import { BiLike as BiLikeIcon } from "react-icons/bi";
import { Box, Flex, Tooltip } from "@chakra-ui/react"

const Like = () => {
    return (
        <Tooltip
        hasArrow
        label={"Like"}
        placement='right'
        ml={1}
        openDelay={500}
        display={{base:'block', md:'none'}}
        fontFamily={"'Orbitron', sans-serif"}
        >
            <Flex
                alignItems={'center'}
                gap={4}
                _hover={{ borderColor: 'yellow.400', borderWidth: '1px' }}
                borderRadius={6}
                p={2}
                w={{base:10, md: 'full'}}
                justifyContent={{base:'center', md:'flex-start'}}
            >
                <BiLikeIcon size={20}/> 
                <Box 
        display={{base:"none", md: "block"}} 
        fontFamily={"'Orbitron', sans-serif"} 
        color="#8d8d8d"
        css={{
            textShadow: "0 0 2px yellow.400, 0 0 3px yellow.400, 0 0 4px yellow.400",
            mixBlendMode: "difference",
        }}
    >
                 Like
                </Box>
            </Flex>
        </Tooltip>
    )
}

export default Like;

*/