import React, { MutableRefObject, useRef } from "react";

import { Button, Center, FormControl, VStack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { OnboardingViewProps as ViewProps } from "./props";
import useShowToast from "../../hooks/useShowToast";
import LabeledInput from "../../components/LabeledInput/LabeledInput";

const OnboardingView = ({ viewModel }: ViewProps) => {
  const { loading, networkError, validationError, clearNetworkError, submit } =
    viewModel;
  const fileInputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const horizontalTilesRef = useRef<HTMLDivElement>(null);
  const showToast = useShowToast();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "onSubmit",
  });

  if (networkError) {
    showToast("Error", networkError, "error");
    clearNetworkError();
  }

  return (
    <>
      <Center
        as="form"
        h={{ md: "full" }}
        p={4}
        pt={{ base: 12, md: 4 }}
        onSubmit={handleSubmit(submit)}
      >
        <VStack w="full" maxW={600}>
          <FormControl>
            <VStack spacing={4}>
              <LabeledInput
                title="Username"
                placeholder="Enter a username"
                error={errors.username?.message as string}
                {...register("username", {
                  required: { value: true, message: "Username is required." },
                  validate: (fieldValue) =>
                    fieldValue.length > 3 ||
                    "Invalid username, must be at least 4 characters.",
                })}
              />
              <LabeledInput
                title="Name"
                placeholder="Enter your name"
                error={errors.name?.message as string}
                {...register("name", {
                  required: { value: true, message: "Name is required." },
                  validate: (fieldValue) =>
                    fieldValue.length > 1 ||
                    "Invalid name, must be at least 2 characters.",
                })}
              />
            </VStack>
          </FormControl>
          <Button
            mt={4}
            isLoading={loading}
            opacity={!isValid ? 0.4 : undefined}
            fontFamily="Orbitron, sans-serif"
            type="submit"
            px={20}
            py={6}
          >
            Submit
          </Button>
        </VStack>
      </Center>
    </>
  );
};

export default OnboardingView;
