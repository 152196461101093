/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/


import React from 'react';
import { Flex, Avatar, Text, Divider } from "@chakra-ui/react";
import useShowToast from '../hooks/useShowToast'
import { DeleteIcon } from '@chakra-ui/icons';
import { useDeletePostCommentMutation } from "./ArrowItems/networking/__generated__/delete-post-comment-mutation.generated";

//this is the comment in UI

const Comment = ({ reply, lastReply }) => {
  const showToast = useShowToast();

  const [deletePostCommentAPI] = useDeletePostCommentMutation();

  const handleDeleteComment = async (id) => {
    try {
      if (!window.confirm("Are you sure you want to delete this comment?"))
        return;

      await deletePostCommentAPI({
        variables: {
          id,
        },
        refetchQueries: ["PostComments"],
      });
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };
  return (
    <>
      <Flex gap={4} py={2} my={2} w={"full"}>
        {reply.user.avatarAsset?.url ? (
          <Avatar src={reply.user.avatarAsset.url} size={"sm"} />
        ) : (
          <Avatar
            size={"sm"}
            name={
              reply.user.username
                ? reply.user.username.charAt(0).toUpperCase()
                : ""
            }
          />
        )}
        <Flex gap={1} w={"full"} flexDirection={"column"}>
          <Flex
            w={"full"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Text
              fontSize="sm"
              fontWeight="bold"
              fontFamily={"Orbitron, sans-serif"}
              color="rgb(135,206,250)"
            >
              {reply.user.username}
            </Text>
            {reply.canDelete && (
              <DeleteIcon
                cursor="pointer"
                onClick={() => {
                  if (typeof reply.id === "undefined") {
                    console.error(
                      "reply.id is undefined. Cannot delete comment.",
                    );
                    return;
                  }
                  handleDeleteComment(reply.id);
                }}
                color={"yellow.200"}
              />
            )}
          </Flex>
          <Text color={"white"} fontFamily={"Orbitron, sans-serif"}>
            {reply.text}
          </Text>
        </Flex>
      </Flex>
      {!lastReply ? <Divider /> : null}
    </>
  );
};


export default Comment;
