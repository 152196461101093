import * as Types from '../../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePostCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type DeletePostCommentMutation = { __typename?: 'Mutation', id?: string | null };


export const DeletePostCommentDocument = gql`
    mutation DeletePostComment($id: String!) {
  id: deletePostComment(id: $id)
}
    `;
export type DeletePostCommentMutationFn = Apollo.MutationFunction<DeletePostCommentMutation, DeletePostCommentMutationVariables>;

/**
 * __useDeletePostCommentMutation__
 *
 * To run a mutation, you first call `useDeletePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostCommentMutation, { data, loading, error }] = useDeletePostCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostCommentMutation, DeletePostCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostCommentMutation, DeletePostCommentMutationVariables>(DeletePostCommentDocument, options);
      }
export type DeletePostCommentMutationHookResult = ReturnType<typeof useDeletePostCommentMutation>;
export type DeletePostCommentMutationResult = Apollo.MutationResult<DeletePostCommentMutation>;
export type DeletePostCommentMutationOptions = Apollo.BaseMutationOptions<DeletePostCommentMutation, DeletePostCommentMutationVariables>;