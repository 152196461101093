import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEducationsMutationVariables = Types.Exact<{
  educations: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type UpdateEducationsMutation = { __typename?: 'Mutation', updateSelectedUser: { __typename?: 'User', id: string, educations: Array<string> } };


export const UpdateEducationsDocument = gql`
    mutation UpdateEducations($educations: [String!]!) {
  updateSelectedUser(input: {educations: $educations}) {
    id
    educations
  }
}
    `;
export type UpdateEducationsMutationFn = Apollo.MutationFunction<UpdateEducationsMutation, UpdateEducationsMutationVariables>;

/**
 * __useUpdateEducationsMutation__
 *
 * To run a mutation, you first call `useUpdateEducationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEducationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEducationsMutation, { data, loading, error }] = useUpdateEducationsMutation({
 *   variables: {
 *      educations: // value for 'educations'
 *   },
 * });
 */
export function useUpdateEducationsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEducationsMutation, UpdateEducationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEducationsMutation, UpdateEducationsMutationVariables>(UpdateEducationsDocument, options);
      }
export type UpdateEducationsMutationHookResult = ReturnType<typeof useUpdateEducationsMutation>;
export type UpdateEducationsMutationResult = Apollo.MutationResult<UpdateEducationsMutation>;
export type UpdateEducationsMutationOptions = Apollo.BaseMutationOptions<UpdateEducationsMutation, UpdateEducationsMutationVariables>;