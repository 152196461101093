import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApplyForCredentialMutationVariables = Types.Exact<{
  input: Types.ApplyForCredentialInput;
}>;


export type ApplyForCredentialMutation = { __typename?: 'Mutation', applyForCredential: boolean };


export const ApplyForCredentialDocument = gql`
    mutation ApplyForCredential($input: ApplyForCredentialInput!) {
  applyForCredential(input: $input)
}
    `;
export type ApplyForCredentialMutationFn = Apollo.MutationFunction<ApplyForCredentialMutation, ApplyForCredentialMutationVariables>;

/**
 * __useApplyForCredentialMutation__
 *
 * To run a mutation, you first call `useApplyForCredentialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyForCredentialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyForCredentialMutation, { data, loading, error }] = useApplyForCredentialMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyForCredentialMutation(baseOptions?: Apollo.MutationHookOptions<ApplyForCredentialMutation, ApplyForCredentialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyForCredentialMutation, ApplyForCredentialMutationVariables>(ApplyForCredentialDocument, options);
      }
export type ApplyForCredentialMutationHookResult = ReturnType<typeof useApplyForCredentialMutation>;
export type ApplyForCredentialMutationResult = Apollo.MutationResult<ApplyForCredentialMutation>;
export type ApplyForCredentialMutationOptions = Apollo.BaseMutationOptions<ApplyForCredentialMutation, ApplyForCredentialMutationVariables>;