// On HomePage and Global
/* Hail Mary full of grace, the Lord is with You, Blessed are you among women, and blessed is the Fruit
Of Your Womb Jesus. Holy Mary, Mother of God, pray for us sinners, now and at the Hour of our death*/

import { Avatar } from '@chakra-ui/avatar'
import { Box, Flex, Image, Text } from '@chakra-ui/react'
import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import useShowToast from '../hooks/useShowToast'
import { formatDistanceToNow } from 'date-fns'
import { DeleteIcon } from "@chakra-ui/icons";
import PostFooter from './PostFooter'
import { useLocation } from 'react-router-dom'
import Hls from 'hls.js';
import { useMe } from "../providers/me-provider";
import { useDeletePostMutation } from "../pages/networking/__generated__/delete-post-mutation.generated";
import { RemoteAssetViewer } from "../utils/use-upload-files";

const Post = ({ post }) => {
  const showToast = useShowToast();
  const { me: currentUser } = useMe();
  const navigate = useNavigate();

  const location = useLocation();

  const [deletePostAPI] = useDeletePostMutation({
    variables: {
      id: post.id,
    },
    refetchQueries: ["Timeline"],
  });

  const handleDeletePost = async (e) => {
    try {
      e.preventDefault();
      if (!window.confirm("Are you sure you want to delete this post?")) return;
      await deletePostAPI({
        refetchQueries: ["Timeline"],
      });
      showToast("Success", "Post deleted", "success");
    } catch (error) {
      showToast("Error", error.message, "error");
    }
  };

  const asset = post.assets.length ? post.assets[0] : null;

  return (
    <>
      <Link to={`/${post.user.username}/post/${post.id}`}>
        <Flex gap={3} mb={4} py={5}>
          <Flex flexDirection={"column"} alignItems={"center"}>
            <Avatar
              size="md"
              name={post.user.displayName}
              src={post.user.avatarAsset?.url}
              onClick={(e) => {
                e.preventDefault();
                navigate(`/${post.user.username}`);
              }}
            />
            <Box w="1px" h={"full"} bg="rgb(135,206,250)" my={2}></Box>
          </Flex>
          <Flex flex={1} flexDirection={"column"} gap={2}>
            <Flex justifyContent={"space-between"} w={"full"}>
              <Flex w={"full"} alignItems={"center"}>
                <Text
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  color={"rgb(135, 206, 250)"}
                  fontFamily={"Orbitron, sans-serif"}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/${post.user.username}`);
                  }}
                >
                  {post.user.username}
                </Text>
              </Flex>
              <Flex gap={4} alignItems={"center"}>
                <Text
                  fontSize={"xs"}
                  width={36}
                  textAlign={"right"}
                  color={"rgb(135, 206, 250)"}
                  fontFamily={"Orbitron, sans-serif"}
                >
                  {formatDistanceToNow(new Date(post.scheduledAt))} ago
                </Text>
                {currentUser?.id === post.user.id &&
                  location.pathname.includes(`/${post.user.username}`) && (
                    <DeleteIcon
                      size={"20"}
                      onClick={handleDeletePost}
                      color={"yellow.200"}
                    />
                  )}
              </Flex>
            </Flex>
            <Text
              fontSize={"sm"}
              color={"white"}
              fontFamily={"Orbitron, sans-serif"}
            >
              {post.text}
            </Text>

            {asset && (
              <Box
                borderRadius={6}
                overflow={"hidden"}
                border={"1px solid white"}
                borderColor={"gray.light"}
                onClick={(e) => {
                  e.preventDefault(); // allow user to click play in line on videos
                }}
              >
                <RemoteAssetViewer asset={asset} />
              </Box>
            )}
          </Flex>
        </Flex>
      </Link>
      <Flex gap={3} my={1} mt={-10}>
        <PostFooter post={post} />
      </Flex>
    </>
  );
};

export default Post 
