 /* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/
import React, { useEffect, useState } from "react";
import { Box, Flex, Skeleton, SkeletonCircle } from "@chakra-ui/react";
import SuggestedUser from "./SuggestedUser";
import useShowToast from "../../hooks/useShowToast";
import { useMediaQuery } from "@chakra-ui/react";
import { useSuggestedUsersQuery } from "./networking/__generated__/suggested-users-query.generated";

const SuggestedUsers = ({ user }) => {
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [isIpadEsq] = useMediaQuery(
    "(min-width: 768px) and (min-height: 1024px)",
  );
  const showToast = useShowToast();
  const { data, error, loading } = useSuggestedUsersQuery();

  useEffect(() => {
    if (!error) {
      return;
    }

    showToast("Error", error.message, "error");
  }, [error]);

  useEffect(() => {
    setSuggestedUsers(data?.suggestedUsers ?? []);
  }, [data]);

  const handleButtonClick = (id) => {
    setTimeout(() => {
      setSuggestedUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== id),
      );
    }, 6000);
  };

  const suggestedUsersToDisplay = isIpadEsq
    ? suggestedUsers.slice(0, 5)
    : suggestedUsers.slice(0, 3);

  return (
    <Flex direction={"column"} gap={4}>
      {!loading &&
        suggestedUsersToDisplay.map((user) => (
          <SuggestedUser
            key={user.id}
            user={user}
            onButtonClick={() => handleButtonClick(user.id)}
          />
        ))}
      {loading &&
        [0, 1, 2, 3, 4].map((_, idx) => (
          <Flex
            key={idx}
            gap={2}
            position="absolute"
            left={0}
            p={"1"}
            borderRadius={"md"}
          >
            <Box>
              <SkeletonCircle size={10} />
            </Box>
            <Flex w={"full"} flexDirection={"column"} gap={3}>
              <Skeleton h={"8px"} w={"80px"} />
              <Skeleton h={"8px"} w={"90%"} />
            </Flex>
            <Flex>
              <Skeleton h={"20px"} w={"90px"} />
            </Flex>
          </Flex>
        ))}
    </Flex>
  );
};

export default SuggestedUsers
