/*שָלוֹם לָךְ, מִרְיָם,
מְלֵאַת הַחֶסֶד,
ה׳ עִמָּךְ,
בְּרוּכָה אַתְּ בַּנָשִׁים
וּבָרוּךְ פְּרִי בִטְנֵךְ יֵשׁוּעַ,
מִרְיָם הַקְּדוֹשָׁה,
אֵם הָאֱלֹהִים,
הִתְפַּלְּלִי בַּעֲדֵנוּ, הַחוֹטְאִים,
עַתָּה וּבִשְׁעַת מוֹתֵנוּ. */

import React, { useEffect, useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import { useNavigate } from "react-router-dom";
import { Text, useDisclosure } from "@chakra-ui/react";
import {
  Tooltip,
  Flex,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import { FaRegArrowAltCircleRight } from "react-icons/fa";
import useShowToast from "../hooks/useShowToast";
import { Avatar } from "@chakra-ui/avatar";
import { useSearchUsersLazyQuery } from "./networking/__generated__/search-users-query.generated";
import { useSearchPostsLazyQuery } from "./networking/__generated__/search-posts-query.generated";

function Search() {
  const [query, setQuery] = useState("");
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [tags, setTags] = useState([]);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const showToast = useShowToast();
  const autoSuggestRef = useRef(null);

  const originalSuggestions = [];

  const [searchUsersAPI, { error: searchUsersError }] =
    useSearchUsersLazyQuery();
  const [searchPostsAPI, { error: searchPostsError }] =
    useSearchPostsLazyQuery();

  useEffect(() => {
    if (!searchUsersError && !searchPostsError) {
      return;
    }

    showToast(
      "Error",
      searchUsersError?.message ?? searchPostsError?.message,
      "error",
    );
  }, [searchUsersError]);

  const getSuggestions = async (value) => {
    const tags = value.split(",");
    const lastTag = tags[tags.length - 1].trim();
    const inputValue = lastTag.toLowerCase();

    try {
      const [usersResponse, postsResponse] = await Promise.all([
        searchUsersAPI({
          variables: {
            query: inputValue,
          },
        }),
        searchPostsAPI({
          variables: {
            query: inputValue,
          },
        }),
      ]);

      const users = usersResponse.data?.users.map((user) => ({
        name: user.username,
        profilePic: user.avatarAsset?.url,
        type: "user",
        url: `/${user.username}`,
      }));
      const posts = postsResponse.data?.posts.map((post) => ({
        name: post.text,
        type: "post",
        url: `${post.user.username}/post/${post.id}`,
      }));

      if (!users?.length && !posts?.length) {
        return [
          {
            name: `No results found for "${inputValue}"`,
            type: "no-results",
          },
        ];
      }

      return [...(users ?? []), ...(posts ?? [])];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion.name;
  };

  const renderSuggestion = (suggestion) => (
    <div style={{ display: "flex", alignItems: "center", color: "#4A5568" }}>
      {suggestion.type === "user" && (
        <>
          <Avatar
            name={suggestion.displayName}
            src={suggestion.avatarAsset?.url}
            size="sm"
            marginRight="10px"
          />
          <Text>{suggestion.name}</Text>
        </>
      )}
      {suggestion.type === "post" && (
        <Text noOfLines={1} fontSize="xs">
          &quot;{suggestion.name}&quot;
        </Text>
      )}
      {suggestion.type === "no-results" && (
        <Text fontSize="xs" fontStyle="italic">
          {suggestion.name}
        </Text>
      )}
    </div>
  );

  const inputProps = {
    placeholder: "Search",
    value,
    onChange: (event, { newValue }) => {
      setValue(newValue);
      setQuery(newValue);
    },
    style: {
      fontFamily: "Orbitron, sans-serif",
      backgroundColor: "black",
      color: "white",
      border: "1px solid white",
      borderRadius: "5px",
      width: "400px",
      height: "40px",
    },
  };

  const renderInputComponent = (inputProps) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input {...inputProps} />
    </div>
  );

  const onSuggestionSelected = (event, { suggestion }) => {
    navigate(suggestion.url);
    onClose();
  };

  const handleSearch = async (event) => {
    event.preventDefault();

    // @ts-ignore
    setSuggestions(await getSuggestions(value));
  };

  const handleButtonClick = () => {
    const mockEvent = {
      preventDefault: () => {},
    };

    handleSearch(mockEvent);
    // @ts-ignore
    autoSuggestRef.current?.input.focus();
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    // @ts-ignore
    setTimeout(() => autoSuggestRef.current?.input.focus(), 100);
  }, [isOpen]);

  return (
    <>
      <Tooltip
        hasArrow
        label={"Search"}
        placement="right"
        ml={1}
        openDelay={500}
        display={{ base: "block", md: "none" }}
        fontFamily={"'Orbitron', sans-serif"}
      >
        <Flex
          alignItems={"center"}
          gap={4}
          _hover={{ borderColor: "yellow.400", borderWidth: "1px" }}
          borderRadius={6}
          p={2}
          w={{ base: 10, md: "full" }}
          justifyContent={{ base: "center", md: "flex-start" }}
          onClick={onOpen}
        >
          <Box
            display="block"
            fontFamily={"'Orbitron', sans-serif"}
            color="#8d8d8d"
            css={{
              textShadow:
                "0 0 2px yellow.400, 0 0 3px yellow.400, 0 0 4px yellow.400",
              mixBlendMode: "difference",
            }}
          >
            Search
          </Box>
        </Flex>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInLeft">
        <ModalOverlay />
        <ModalContent
          bg={"black"}
          border={"1px solid gray"}
          maxW={"400"}
          fontFamily={"Orbitron, sans-serif"}
        >
          <ModalHeader color={"rgb(135, 206, 250)"}>Search</ModalHeader>
          <ModalCloseButton color="red.500" />
          <ModalBody pb={6} position="relative">
            <form onSubmit={handleSearch} style={{ marginRight: "25px" }}>
              <FormControl>
                <FormLabel></FormLabel>

                <Autosuggest
                  ref={autoSuggestRef}
                  suggestions={suggestions}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  renderInputComponent={renderInputComponent}
                  onSuggestionSelected={onSuggestionSelected}
                  shouldRenderSuggestions={() => true}
                />
              </FormControl>
              <Flex w={"full"} justifyContent={"flex-end"}></Flex>
            </form>
            <Button
              type="submit"
              position="absolute"
              top="20px"
              left="355px"
              size="sm"
              isLoading={loading}
              _hover={{ borderColor: "red.500", borderWidth: "1px" }}
              onClick={handleButtonClick}
            >
              <FaRegArrowAltCircleRight />
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Search;
