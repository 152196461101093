import { Presence } from "../../__generated__/graphql-types.generated";

// !!Note!!: Make sure to add new keys to socketInboundEventSubscriptionNames otherwise they won't be subscribed to
export type SocketInboundEvents = {
  authSuccess: { userId: string };
  authFailure: { error: string };
  newMessage: {
    id: string;
    conversationId: string;
    messageId: string;
    actorId: string;
  };
};

export const socketInboundEventSubscriptionNames: SocketInboundEventName[] = [
  "authSuccess",
  "authFailure",
  "newMessage",
];

export type SocketOutboundEvents = {
  auth: { token: string; managedUserId?: string };
  "auth:disconnect": {};
};

export type SocketInboundEventName = keyof SocketInboundEvents;
export type SocketOutboundEventName = keyof SocketOutboundEvents;

export type SocketInboundHandlers = {
  [Name in SocketInboundEventName]: (data: SocketInboundEvents[Name]) => void;
};
