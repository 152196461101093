import { useCallback } from "react";
import { SocketOutboundEventName, SocketOutboundEvents } from "./models";
import { socket } from "./socket";

const useSendEvent = () => {
  const sendEvent = useCallback(
    async <Name extends SocketOutboundEventName>(
      name: Name,
      data: SocketOutboundEvents[Name],
    ) => {
      socket.emit(name, data);
    },
    [],
  );

  return sendEvent;
};

export default useSendEvent;
