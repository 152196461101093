import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Post from '../components/Post';
import { Heading, useMediaQuery } from '@chakra-ui/react';
import { Box, Flex, GridItem, Spinner, Text, VStack } from '@chakra-ui/react'
import CreateQueenBee from '../components/CreateQueenBee';
import HiveMindSearch from '../components/HiveMindSearch';
import { useTimelineQuery } from "./networking/__generated__/timeline-query.generated";
import useShowToast from "../hooks/useShowToast";
import { useTagsQuery } from "../components/networking/__generated__/tags-query.generated";

const HiveMindPage = () => {
  const { tags } = useParams();
  const showToast = useShowToast();
  const [isSmallScreen] = useMediaQuery(
    "(min-width: 375px) and (max-width: 430px)",
  );

  const { data: tagsData, error: tagsError } = useTagsQuery({
    variables: {
      limit: 1000,
    },
    fetchPolicy: "cache-first",
  });
  const allTags = tagsData?.tags.edges.map(({ node }) => node) || [];
  const tagId = !!tags
    ? allTags.find((t) => t.name.toLowerCase() === tags.toLowerCase())?.id
    : undefined;

  const { data: timelineData, error: timelineError } = useTimelineQuery({
    variables: {
      tagId,
    },
    skip: !tagId,
  });
  const posts = timelineData?.timeline.edges.map(({ node }) => node) || [];

  useEffect(() => {
    if (!tagsError && !timelineError) {
      return;
    }

    showToast("Error", tagsError?.message ?? timelineError?.message, "error");
  }, [tagsError, timelineError]);

  const firstColumnPosts = posts.filter((_, index) => index % 2 === 0);
  const secondColumnPosts = posts.filter((_, index) => index % 2 === 1);

  return (
    <VStack spacing={4}>
      <Flex direction="column" align="center" justify="center">
        <Heading
          as="h1"
          size="xl"
          textAlign={isSmallScreen ? "left" : "center"}
          ml={isSmallScreen ? "-55" : "0"}
          marginBottom="20px"
          fontFamily={"Orbitron, sans-serif"}
          color="yellow.400"
        >
          {tags}
        </Heading>
        <Box ml={isSmallScreen ? "-25" : "0"}>
          <HiveMindSearch />
        </Box>
      </Flex>

      {posts.length === 0 ? (
        <Text fontFamily={"Orbitron, sans-serif"} color="yellow.400">
          No QueenBees available.
        </Text>
      ) : isSmallScreen ? (
        <VStack spacing={4} width="100%" marginLeft={-50}>
          {posts.map((post, index) => (
            <Box
              key={index}
              bg={index % 2 === 0 ? "gray.800" : "black"}
              borderRadius="lg"
            >
              <Post post={post} />
            </Box>
          ))}
        </VStack>
      ) : (
        <Flex marginLeft="-150px">
          <VStack spacing={4} width="20%" marginRight={-100} marginLeft={150}>
            {firstColumnPosts.map((post, index) => (
              <Box
                key={index}
                bg={index % 2 === 0 ? "gray.800" : "black"}
                borderRadius="lg"
              >
                <Post post={post} />
              </Box>
            ))}
          </VStack>

          <VStack spacing={4} width="20%" marginRight={-100} marginLeft={400}>
            {secondColumnPosts.map((post, index) => (
              <Box
                key={index}
                bg={index % 2 === 0 ? "gray.800" : "black"}
                borderRadius="lg"
              >
                <Post post={post} />
              </Box>
            ))}
          </VStack>
        </Flex>
      )}
      <Flex
        justifyContent={isSmallScreen ? "flex-start" : "center"}
        ml={isSmallScreen ? "50px" : "-100"}
      >
        <CreateQueenBee tag={tags} />
      </Flex>
    </VStack>
  );
};

export default HiveMindPage;


/*
  <VStack spacing={4} width="20%" marginLeft={136}>
      {thirdColumnPosts.map((post, index) => (
        <Box key={index} bg={index % 2 === 0 ? 'gray.800' : 'black'}>
          <Post post={post} />
        </Box>
      ))}
    </VStack>
*/