import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FollowersQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['String']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type FollowersQuery = { __typename?: 'Query', followers: { __typename?: 'FollowConnection', pageInfo: { __typename?: 'LimitOffsetPageInfo', totalEdges: number, offset: number, limit: number, hasPreviousPage: boolean, hasNextPage: boolean }, edges: Array<{ __typename?: 'FollowEdge', node: { __typename?: 'User', id: string, username: string, displayName: string, avatarAsset?: { __typename?: 'Asset', id: string, url: string } | null } }> } };


export const FollowersDocument = gql`
    query Followers($userId: String, $limit: Int, $offset: Int) {
  followers(userId: $userId, limit: $limit, offset: $offset) {
    pageInfo {
      totalEdges
      offset
      limit
      hasPreviousPage
      hasNextPage
    }
    edges {
      node {
        id
        username
        displayName
        avatarAsset {
          id
          url
        }
      }
    }
  }
}
    `;

/**
 * __useFollowersQuery__
 *
 * To run a query within a React component, call `useFollowersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFollowersQuery(baseOptions?: Apollo.QueryHookOptions<FollowersQuery, FollowersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FollowersQuery, FollowersQueryVariables>(FollowersDocument, options);
      }
export function useFollowersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowersQuery, FollowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FollowersQuery, FollowersQueryVariables>(FollowersDocument, options);
        }
export function useFollowersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FollowersQuery, FollowersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FollowersQuery, FollowersQueryVariables>(FollowersDocument, options);
        }
export type FollowersQueryHookResult = ReturnType<typeof useFollowersQuery>;
export type FollowersLazyQueryHookResult = ReturnType<typeof useFollowersLazyQuery>;
export type FollowersSuspenseQueryHookResult = ReturnType<typeof useFollowersSuspenseQuery>;
export type FollowersQueryResult = Apollo.QueryResult<FollowersQuery, FollowersQueryVariables>;