/* Ave Maria gratia plena, Dominus tecum, benedicta tu in mulieribus et benedictus fructus 
ventris tui Iesu, Santa Maria, mater Dei, ora pro nobis peccatoribus, nunc et in ora mortis nostrae */

import { Avatar, Divider, Flex, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react'
import Message from './Message'
import MessageInput from './MessageInput'
import { useEffect, useRef, useState } from 'react'
import useShowToast from '../hooks/useShowToast'
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMe } from "../providers/me-provider";
import { useConversationMessagesQuery } from "../pages/networking/__generated__/conversation-messages-query.generated";
import { useApolloClient } from "@apollo/client";
import { ConversationFragmentFragmentDoc } from "../pages/networking/__generated__/conversation-fragment.generated";
import { useMarkConversationReadMutation } from "../pages/networking/__generated__/mark-conversation-as-read-mutation.generated";

const MessageContainer = () => {
  const showToast = useShowToast();
  const { me: currentUser } = useMe();
  const messageEndRef = useRef(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const selectedConversationId = searchParams.get("id");
  const client = useApolloClient();

  const conversation = client.readFragment({
    id: `Conversation:${selectedConversationId}`,
    fragment: ConversationFragmentFragmentDoc,
    fragmentName: "ConversationFragment",
  });

  const { data: messagesData, error: messagesError } =
    useConversationMessagesQuery({
      variables: {
        id: selectedConversationId,
        limit: 100,
      },
      skip: !selectedConversationId,
    });

  const [markConversationReadAPI, { error: markConversationAsReadError }] =
    useMarkConversationReadMutation();

  useEffect(
    () => {
      if (conversation?.read === false) {
        markConversationReadAPI({
          variables: {
            id: conversation.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            conversation: {
              __typename: "Conversation",
              id: conversation.id,
              read: true,
            },
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversation],
  );

  useEffect(() => {
    const error = messagesError || markConversationAsReadError;

    if (!error) {
      return;
    }

    showToast("Error", error?.message, "error");
  }, [messagesError, markConversationAsReadError]);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messagesData]);

  return (
    <Flex
      flex="70"
      bg={"black"}
      borderRadius={"md"}
      p={2}
      flexDirection={"column"}
    >
      <Flex w={"full"} h={12} alignItems={"center"} gap={2}>
        <div
          onClick={(e) => {
            e.preventDefault();
            navigate(`/${conversation?.otherParticipants[0].username}`);
          }}
          style={{ cursor: "pointer" }}
        >
          <Avatar
            src={conversation?.avatar?.url}
            name={conversation?.otherParticipants[0].username}
            size={"sm"}
          />
        </div>
        <Text
          display={"flex"}
          alignItems={"center"}
          fontFamily={"Orbitron, sans-serif"}
        >
          {conversation?.otherParticipants[0].username}
        </Text>
      </Flex>
      <Divider borderColor={"rgb(135,206,250)"} />

      <Flex
        flexDir={"column"}
        gap={4}
        my={4}
        p={2}
        maxH={"400px"}
        overflowY={"auto"}
      >
        {!messagesData &&
          [...Array(5)].map((_, i) => (
            <Flex
              key={i}
              gap={2}
              alignItems={"center"}
              p={1}
              borderRadius={"md"}
              alignSelf={i % 2 === 0 ? "flex-start" : "flex-end"}
            >
              {i % 2 === 0 && <SkeletonCircle size={7} />}
              <Flex flexDir={"column"} gap={2}>
                <Skeleton key={`${i}-1`} h="8px" w="250px" />
                <Skeleton key={`${i}-2`} h="8px" w="250px" />
                <Skeleton key={`${i}-3`} h="8px" w="250px" />
              </Flex>
              {i % 2 !== 0 && <SkeletonCircle size={7} />}
            </Flex>
          ))}
        {messagesData?.conversationMessages.edges
          .toReversed()
          .map(({ node: message }, index) => (
            <Flex
              key={message.id}
              direction={"column"}
              ref={
                messagesData?.conversationMessages.edges.length - 1 === index
                  ? messageEndRef
                  : null
              }
            >
              <Message
                message={message}
                ownMessage={currentUser.id === message.userId}
                conversation={conversation}
              />
            </Flex>
          ))}
      </Flex>
      <MessageInput />
    </Flex>
  );
};

export default MessageContainer 
