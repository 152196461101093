import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkHasNoNewMessagesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type MarkHasNoNewMessagesMutation = { __typename?: 'Mutation', messageSummary: { __typename?: 'MessageSummary', id: string, hasNewMessages: boolean } };


export const MarkHasNoNewMessagesDocument = gql`
    mutation MarkHasNoNewMessages {
  messageSummary: markHasNoNewMessages {
    id
    hasNewMessages
  }
}
    `;
export type MarkHasNoNewMessagesMutationFn = Apollo.MutationFunction<MarkHasNoNewMessagesMutation, MarkHasNoNewMessagesMutationVariables>;

/**
 * __useMarkHasNoNewMessagesMutation__
 *
 * To run a mutation, you first call `useMarkHasNoNewMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkHasNoNewMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markHasNoNewMessagesMutation, { data, loading, error }] = useMarkHasNoNewMessagesMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkHasNoNewMessagesMutation(baseOptions?: Apollo.MutationHookOptions<MarkHasNoNewMessagesMutation, MarkHasNoNewMessagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkHasNoNewMessagesMutation, MarkHasNoNewMessagesMutationVariables>(MarkHasNoNewMessagesDocument, options);
      }
export type MarkHasNoNewMessagesMutationHookResult = ReturnType<typeof useMarkHasNoNewMessagesMutation>;
export type MarkHasNoNewMessagesMutationResult = Apollo.MutationResult<MarkHasNoNewMessagesMutation>;
export type MarkHasNoNewMessagesMutationOptions = Apollo.BaseMutationOptions<MarkHasNoNewMessagesMutation, MarkHasNoNewMessagesMutationVariables>;