import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FollowersCountQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type FollowersCountQuery = { __typename?: 'Query', followers: { __typename?: 'FollowConnection', pageInfo: { __typename?: 'LimitOffsetPageInfo', totalEdges: number } } };


export const FollowersCountDocument = gql`
    query FollowersCount($userId: String) {
  followers(userId: $userId, limit: 0, offset: 0) {
    pageInfo {
      totalEdges
    }
  }
}
    `;

/**
 * __useFollowersCountQuery__
 *
 * To run a query within a React component, call `useFollowersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFollowersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFollowersCountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFollowersCountQuery(baseOptions?: Apollo.QueryHookOptions<FollowersCountQuery, FollowersCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FollowersCountQuery, FollowersCountQueryVariables>(FollowersCountDocument, options);
      }
export function useFollowersCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FollowersCountQuery, FollowersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FollowersCountQuery, FollowersCountQueryVariables>(FollowersCountDocument, options);
        }
export function useFollowersCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FollowersCountQuery, FollowersCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FollowersCountQuery, FollowersCountQueryVariables>(FollowersCountDocument, options);
        }
export type FollowersCountQueryHookResult = ReturnType<typeof useFollowersCountQuery>;
export type FollowersCountLazyQueryHookResult = ReturnType<typeof useFollowersCountLazyQuery>;
export type FollowersCountSuspenseQueryHookResult = ReturnType<typeof useFollowersCountSuspenseQuery>;
export type FollowersCountQueryResult = Apollo.QueryResult<FollowersCountQuery, FollowersCountQueryVariables>;