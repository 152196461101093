import { useCallback, useMemo } from "react";
import { SocketInboundEventName, SocketInboundEvents } from "./models";
import { useApolloClient } from "@apollo/client";
import useShowToast from "../hooks/useShowToast";
import {
  MessageSummaryDocument,
  MessageSummaryQuery,
} from "../pages/networking/__generated__/message-summary-query.generated";
import {
  MessageDocument,
  MessageQuery,
  MessageQueryVariables,
} from "../pages/networking/__generated__/message-query.generated";
import {
  ConversationDocument,
  ConversationQuery,
  ConversationQueryVariables,
} from "../pages/networking/__generated__/conversation-query.generated";
import { addNewInboundMessageToCache } from "../utils/add-new-message-to-cache";
import { useSearchParams } from "react-router-dom";
import { useMe } from "../providers/me-provider";

type Handlers = {
  [Name in SocketInboundEventName]: (data: SocketInboundEvents[Name]) => void;
};

const useHandleInboundEvent = () => {
  const showToast = useShowToast();
  const client = useApolloClient();
  const [searchParams] = useSearchParams();
  const { me } = useMe();

  const handlers: Handlers = useMemo(
    () => ({
      authSuccess: (/* data */) => {
        // do nothing
      },
      authFailure: (data) => {
        showToast(
          "Error",
          `Failed to connect to real-time stream. ${data.error}`,
          "error",
        );
      },
      newMessage: async ({ actorId, conversationId, messageId }) => {
        if (actorId === me?.id || !messageId) {
          return;
        }

        client.cache.updateQuery<MessageSummaryQuery>(
          {
            query: MessageSummaryDocument,
          },
          (data) => {
            if (!data) {
              return data;
            }

            return {
              ...data,
              messageSummary: {
                ...data.messageSummary,
                hasNewMessages: true,
              },
            };
          },
        );

        if (!location.pathname.startsWith("/chat")) {
          return;
        }

        try {
          const { data: messageData } = await client.query<
            MessageQuery,
            MessageQueryVariables
          >({
            query: MessageDocument,
            variables: {
              id: messageId,
            },
          });

          const message = messageData.message;

          const { data: conversationData } = await client.query<
            ConversationQuery,
            ConversationQueryVariables
          >({
            query: ConversationDocument,
            variables: {
              id: conversationId,
            },
            fetchPolicy: "cache-first",
          });

          const conversation = conversationData.conversation;

          if (message && conversation) {
            addNewInboundMessageToCache(
              client.cache,
              message,
              conversation,
              searchParams,
            );
          }
        } catch (error) {
          // Do nothing
        }
      },
    }),
    [client, me, searchParams, showToast],
  );

  const handleInboundEvent = useCallback(
    async <Name extends SocketInboundEventName>(
      name: Name,
      data: SocketInboundEvents[Name],
    ) => {
      await handlers[name](data);
    },
    [handlers],
  );

  return handleInboundEvent;
};

export default useHandleInboundEvent;
