import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateInterestsMutationVariables = Types.Exact<{
  interests: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type UpdateInterestsMutation = { __typename?: 'Mutation', updateSelectedUser: { __typename?: 'User', id: string, interests: Array<string> } };


export const UpdateInterestsDocument = gql`
    mutation UpdateInterests($interests: [String!]!) {
  updateSelectedUser(input: {interests: $interests}) {
    id
    interests
  }
}
    `;
export type UpdateInterestsMutationFn = Apollo.MutationFunction<UpdateInterestsMutation, UpdateInterestsMutationVariables>;

/**
 * __useUpdateInterestsMutation__
 *
 * To run a mutation, you first call `useUpdateInterestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInterestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInterestsMutation, { data, loading, error }] = useUpdateInterestsMutation({
 *   variables: {
 *      interests: // value for 'interests'
 *   },
 * });
 */
export function useUpdateInterestsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInterestsMutation, UpdateInterestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInterestsMutation, UpdateInterestsMutationVariables>(UpdateInterestsDocument, options);
      }
export type UpdateInterestsMutationHookResult = ReturnType<typeof useUpdateInterestsMutation>;
export type UpdateInterestsMutationResult = Apollo.MutationResult<UpdateInterestsMutation>;
export type UpdateInterestsMutationOptions = Apollo.BaseMutationOptions<UpdateInterestsMutation, UpdateInterestsMutationVariables>;