/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/


import React, { useState, useEffect  } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { Input } from '@chakra-ui/input';
import { useTagsQuery } from "./networking/__generated__/tags-query.generated";
import useShowToast from "../hooks/useShowToast";

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [notFound, setNotFound] = useState(false);
  const navigate = useNavigate();
  const showToast = useShowToast();
  const [loading, setLoading] = useState(false);
  const { data: tagsData, error: tagsError } = useTagsQuery({
    variables: {
      limit: 1000,
    },
    fetchPolicy: "cache-first",
  });
  const allTags = tagsData?.tags.edges.map(({ node }) => node) || [];
  const [filteredTags, setFilteredTags] = useState([]);

  const parentStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
  };

  const suggestionsStyle = {
    position: "absolute",
    top: "100%",
    left: "0",
    zIndex: 2000,
    backgroundColor: "black",
    border: "1px solid yellow",
    borderRadius: "4px",
    width: "270px",
    fontFamily: "Orbitron, sans-serif",
  };

  useEffect(() => {
    if (!tagsError) {
      return;
    }

    showToast("Error", tagsError.message, "error");
  }, [tagsError]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredTags([]);
    } else {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const exactMatch = allTags.some(
        (tag) => tag.name.toLowerCase() === lowerCaseSearchTerm,
      );
      if (exactMatch) {
        setFilteredTags([]);
      } else {
        const filtered = allTags
          .filter((tag) => tag.name.toLowerCase().includes(lowerCaseSearchTerm))
          .slice(0, 10);
        setFilteredTags(filtered.map((tag) => tag.name));
      }
    }
  }, [searchTerm, allTags]);

  const handleSuggestionClick = (tag) => {
    setSearchTerm(tag);
    setFilteredTags([]);
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setNotFound(false);
    setLoading(true);

    const query = searchTerm.trim();
    if (!query) {
      console.error("Query is empty");
      setLoading(false);
      return;
    }

    const tag = allTags.find(
      (tag) => tag.name.toLowerCase() === query.toLowerCase(),
    );

    if (tag) {
      navigate(`/tags/${tag.name}`);
    } else {
      console.log("Tag not found");
      setNotFound(true);
    }

    setLoading(false);
  };

  return (
    <form
      onSubmit={handleSearch}
      style={{ display: "flex", alignItems: "center" }}
    >
      <div style={parentStyle}>
        <Input
          className="no-focus-border"
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search Subjects"
          fontFamily="Orbitron, sans-serif"
          style={{
            color: "gray.200",
            borderColor: "gray.200",
            borderWidth: "2px",
            borderStyle: "solid",
          }}
        />
        <Button
          type="submit"
          size="sm"
          isLoading={loading}
          _hover={{ borderColor: "yellow.400", borderWidth: "1px" }}
        >
          <FaRegArrowAltCircleRight />
        </Button>
        {filteredTags.length > 0 && (
          <div style={suggestionsStyle}>
            {filteredTags.map((tag, index) => (
              <div
                key={index}
                style={{ padding: "5px" }}
                onClick={() => handleSuggestionClick(tag)}
              >
                {tag}
              </div>
            ))}
          </div>
        )}
      </div>
    </form>
  );
};


export default SearchBar;