import { useCreateAssetMutation } from "./networking/__generated__/create-asset-mutation.generated";
import { Asset, AssetType } from "../../__generated__/graphql-types.generated";
import { useMe } from "../providers/me-provider";
import ReactPlayer from "react-player";
import { Image } from "@chakra-ui/react";
import { v4 as uuid } from "uuid";

// @ts-ignore
const cloudName = import.meta.env.VITE_CLOUDINARY_CLOUD_NAME;
if (!cloudName) {
  throw new Error("Missing VITE_CLOUDINARY_CLOUD_NAME env var");
}

const useUploadFiles = () => {
  const { me } = useMe();
  const [createAssetAPI] = useCreateAssetMutation();

  const upload = async (
    file: File,
    onProgress?: (progress: number, file: File) => void,
  ): Promise<Asset> => {
    const validationError = validateFile(file);
    if (validationError) {
      throw new Error(validationError);
    }

    if (shouldUploadInChunks(file)) {
      return uploadFileInChunks(file, onProgress);
    }

    return uploadFileInOneCall(file, onProgress);
  };

  const uploadFileInOneCall = async (
    file: File,
    onProgress?: (progress: number, file: File) => void,
  ): Promise<Asset> => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", `https://api.cloudinary.com/v1_1/${cloudName}/upload`);

    xhr.upload.onprogress = (event) => {
      if (onProgress && event.lengthComputable) {
        const progress = event.loaded / event.total;
        onProgress(progress, file);
      }
    };

    const response = await new Promise<IResponse>((resolve, reject) => {
      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject(new Error(`Failed to upload file ${file.name}`));
        }
      };
      xhr.onerror = () =>
        reject(new Error(`Network error for file ${file.name}`));
      xhr.ontimeout = () =>
        reject(new Error(`Timeout error for file ${file.name}`));
      xhr.send(createFormData(file, me?.id));
    });

    return createAssetInAPI(response.public_id, file);
  };

  const uploadFileInChunks = async (
    file: File,
    onProgress?: (progress: number, file: File) => void,
  ): Promise<Asset> => {
    const chunkSize = 50 * 1024 * 1024; // can go up to 100MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    let uploadedBytes = 0;
    let uniqueUploadId = uuid();
    let response: IResponse | null = null;

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunk = file.slice(start, end);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", `https://api.cloudinary.com/v1_1/${cloudName}/upload`);
      xhr.setRequestHeader("X-Unique-Upload-Id", uniqueUploadId);
      xhr.setRequestHeader(
        "Content-Range",
        `bytes ${start}-${end - 1}/${file.size}`,
      );

      xhr.upload.onprogress = (event) => {
        if (onProgress && event.lengthComputable) {
          const progress = (uploadedBytes + event.loaded) / file.size;
          onProgress(progress, file);
        }
      };

      response = await new Promise<IResponse>((resolve, reject) => {
        xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
            resolve(JSON.parse(xhr.responseText));
          } else {
            reject(
              new Error(
                `Failed to upload chunk ${chunkIndex + 1} for file ${file.name}`,
              ),
            );
          }
        };
        xhr.onerror = () =>
          reject(
            new Error(
              `Network error for chunk ${chunkIndex + 1} of file ${file.name}`,
            ),
          );
        xhr.ontimeout = () =>
          reject(
            new Error(
              `Timeout error for chunk ${chunkIndex + 1} of file ${file.name}`,
            ),
          );
        xhr.send(createFormData(chunk, me?.id));
      });

      uploadedBytes += chunk.size;
    }

    if (!response) {
      throw new Error("Failed to upload video in chunks, no response received");
    }

    return createAssetInAPI(response.public_id, file);
  };

  const createFormData = (chunk: Blob | File, userId: string | undefined) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("upload_preset", "unsigned");
    formData.append("context", `user_id=${userId ?? "<unknown>"}`);
    return formData;
  };

  const createAssetInAPI = async (remoteId: string, file: File) => {
    const assetResponse = await createAssetAPI({
      variables: {
        input: {
          remoteId,
          type: assetTypeForFile(file),
        },
      },
    });

    const asset = assetResponse.data?.asset;
    if (!asset) {
      throw new Error("Failed to create asset");
    }

    return asset;
  };

  return { upload };
};

export default useUploadFiles;

export function assetTypeForFile(file: File): AssetType {
  const fileExtension = file.name.split(".").pop()?.toLowerCase();

  if (fileExtension && supportedImageExtensions.includes(fileExtension)) {
    return AssetType.Image;
  }

  if (fileExtension && supportedVideoExtensions.includes(fileExtension)) {
    return AssetType.Video;
  }

  return AssetType.Other;
}

const validateFile = (file: File): string | null => {
  const assetType = assetTypeForFile(file);

  const videoMaxSize = 100 * 1024 * 1024; // 100MB
  const imageMaxSize = 10 * 1024 * 1024; // 10MB

  if (assetType === AssetType.Image) {
    if (file.size > imageMaxSize) {
      // return `Image ${file.name} exceeds the maximum size of 10MB.`;
    }
  } else if (assetType === AssetType.Video) {
    if (file.size > videoMaxSize) {
      // return `Video ${file.name} exceeds the maximum size of 100MB.`;
    }
  } else {
    return `File ${file.name} is not a supported image or video format.`;
  }

  return null;
};

const shouldUploadInChunks = (file: File): boolean => {
  const maxSingleUploadSize = 100 * 1024 * 1024; // 100MB

  if (file.size >= maxSingleUploadSize) {
    return true;
  }

  return false;
};

interface IResponse {
  access_mode: string;
  asset_id: string;
  audio: IResponseAudio;
  bit_rate: number;
  bytes: number;
  created_at: Date;
  duration: number;
  etag: string;
  folder: string;
  format: string;
  frame_rate: number;
  height: number;
  is_audio: boolean;
  nb_frames: number;
  original_filename: string;
  pages: number;
  placeholder: boolean;
  public_id: string;
  resource_type: string;
  rotation: number;
  secure_url: string;
  signature: string;
  tags: string[];
  type: string;
  url: string;
  version: number;
  version_id: string;
  video: IResponseVideo;
  width: number;
}

interface IResponseAudio {
  bit_rate: string;
  channel_layout: string;
  channels: number;
  codec: string;
  frequency: number;
}

interface IResponseVideo {
  bit_rate: string;
  codec: string;
  dar: string;
  level: number;
  pix_format: string;
  profile: string;
  time_base: string;
}

const supportedImageExtensions = [
  "jpg",
  "jpeg",
  "bmp",
  "gif",
  "png",
  "jfif",
  "heic",
  "webp",
];
const supportedVideoExtensions = [
  "mp4",
  "mpg",
  "mpeg",
  "3gp",
  "webm",
  "flv",
  "wmv",
  "mov",
  "MOV",
];

export const supportedImageTypes = supportedImageExtensions.map(
  (extension) => `image/${extension}`,
);
export const supportedVideoTypes = supportedVideoExtensions.map(
  (extension) => `.${extension}`,
);
export const supportedMediaTypes = [
  ...supportedImageTypes,
  ...supportedVideoTypes,
];

export const LocalFileViewer = ({ localFile }: { localFile: File }) =>
  assetTypeForFile(localFile) === AssetType.Video ? (
    <ReactPlayer
      url={URL.createObjectURL(localFile)}
      controls
      width="320px"
      height="240px"
    />
  ) : (
    <Image src={URL.createObjectURL(localFile)} />
  );

export const RemoteAssetViewer = ({ asset }: { asset: Asset }) =>
  asset.type === AssetType.Video ? (
    <ReactPlayer
      url={asset.url}
      controls
      width="100%"
      height="100%"
      playsinline
      onError={(e) => console.log("Video playback error", e)}
    />
  ) : asset.type === AssetType.Image ? (
    <Image src={asset.url} w={"full"} />
  ) : null;
