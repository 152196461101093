import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { MeFragmentFragmentDoc } from '../../../providers/networking/__generated__/me-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserByUsernameQueryVariables = Types.Exact<{
  username: Types.Scalars['String']['input'];
}>;


export type UserByUsernameQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, username: string, about?: string | null, avatarAssetId?: string | null, displayName: string, email: string, isAdmin: boolean, interests: Array<string>, skills: Array<string>, projects: Array<string>, educations: Array<string>, badges: Array<string>, organization?: string | null, avatarAsset?: { __typename?: 'Asset', id: string, type: Types.AssetType, url: string } | null } | null };


export const UserByUsernameDocument = gql`
    query UserByUsername($username: String!) {
  user(username: $username) {
    ...MeFragment
  }
}
    ${MeFragmentFragmentDoc}`;

/**
 * __useUserByUsernameQuery__
 *
 * To run a query within a React component, call `useUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserByUsernameQuery(baseOptions: Apollo.QueryHookOptions<UserByUsernameQuery, UserByUsernameQueryVariables> & ({ variables: UserByUsernameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserByUsernameQuery, UserByUsernameQueryVariables>(UserByUsernameDocument, options);
      }
export function useUserByUsernameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByUsernameQuery, UserByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserByUsernameQuery, UserByUsernameQueryVariables>(UserByUsernameDocument, options);
        }
export function useUserByUsernameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserByUsernameQuery, UserByUsernameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserByUsernameQuery, UserByUsernameQueryVariables>(UserByUsernameDocument, options);
        }
export type UserByUsernameQueryHookResult = ReturnType<typeof useUserByUsernameQuery>;
export type UserByUsernameLazyQueryHookResult = ReturnType<typeof useUserByUsernameLazyQuery>;
export type UserByUsernameSuspenseQueryHookResult = ReturnType<typeof useUserByUsernameSuspenseQuery>;
export type UserByUsernameQueryResult = Apollo.QueryResult<UserByUsernameQuery, UserByUsernameQueryVariables>;