import * as Types from '../../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SuggestedUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SuggestedUsersQuery = { __typename?: 'Query', suggestedUsers: Array<{ __typename?: 'User', id: string, username: string, displayName: string, avatarAsset?: { __typename?: 'Asset', id: string, url: string } | null }> };


export const SuggestedUsersDocument = gql`
    query SuggestedUsers {
  suggestedUsers: searchUsers(query: "") {
    id
    username
    displayName
    avatarAsset {
      id
      url
    }
  }
}
    `;

/**
 * __useSuggestedUsersQuery__
 *
 * To run a query within a React component, call `useSuggestedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestedUsersQuery(baseOptions?: Apollo.QueryHookOptions<SuggestedUsersQuery, SuggestedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedUsersQuery, SuggestedUsersQueryVariables>(SuggestedUsersDocument, options);
      }
export function useSuggestedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedUsersQuery, SuggestedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedUsersQuery, SuggestedUsersQueryVariables>(SuggestedUsersDocument, options);
        }
export function useSuggestedUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SuggestedUsersQuery, SuggestedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedUsersQuery, SuggestedUsersQueryVariables>(SuggestedUsersDocument, options);
        }
export type SuggestedUsersQueryHookResult = ReturnType<typeof useSuggestedUsersQuery>;
export type SuggestedUsersLazyQueryHookResult = ReturnType<typeof useSuggestedUsersLazyQuery>;
export type SuggestedUsersSuspenseQueryHookResult = ReturnType<typeof useSuggestedUsersSuspenseQuery>;
export type SuggestedUsersQueryResult = Apollo.QueryResult<SuggestedUsersQuery, SuggestedUsersQueryVariables>;