import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
export type MessageFragmentFragment = { __typename?: 'Message', id: string, text?: string | null, userId: string, createdAt: any, assets: Array<{ __typename?: 'Asset', id: string, url: string, type: Types.AssetType }> };

export const MessageFragmentFragmentDoc = gql`
    fragment MessageFragment on Message {
  id
  text
  userId
  createdAt
  assets {
    id
    url
    type
  }
}
    `;