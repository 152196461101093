import * as Types from '../../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
export type PostCommentFragmentFragment = { __typename?: 'PostComment', id: string, canDelete: boolean, parentId?: string | null, text: string, createdAt: any, user: { __typename?: 'User', id: string, displayName: string, username: string, avatarAsset?: { __typename?: 'Asset', id: string, url: string } | null } };

export const PostCommentFragmentFragmentDoc = gql`
    fragment PostCommentFragment on PostComment {
  id
  canDelete
  parentId
  text
  createdAt
  user {
    id
    displayName
    username
    avatarAsset {
      id
      url
    }
  }
}
    `;