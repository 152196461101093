import * as Types from '../../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { PostCommentFragmentFragmentDoc } from './post-comment-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePostCommentMutationVariables = Types.Exact<{
  input: Types.CreatePostCommentInput;
}>;


export type CreatePostCommentMutation = { __typename?: 'Mutation', createPostComment?: { __typename?: 'PostComment', id: string, canDelete: boolean, parentId?: string | null, text: string, createdAt: any, user: { __typename?: 'User', id: string, displayName: string, username: string, avatarAsset?: { __typename?: 'Asset', id: string, url: string } | null } } | null };


export const CreatePostCommentDocument = gql`
    mutation CreatePostComment($input: CreatePostCommentInput!) {
  createPostComment(input: $input) {
    ...PostCommentFragment
  }
}
    ${PostCommentFragmentFragmentDoc}`;
export type CreatePostCommentMutationFn = Apollo.MutationFunction<CreatePostCommentMutation, CreatePostCommentMutationVariables>;

/**
 * __useCreatePostCommentMutation__
 *
 * To run a mutation, you first call `useCreatePostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostCommentMutation, { data, loading, error }] = useCreatePostCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePostCommentMutation, CreatePostCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePostCommentMutation, CreatePostCommentMutationVariables>(CreatePostCommentDocument, options);
      }
export type CreatePostCommentMutationHookResult = ReturnType<typeof useCreatePostCommentMutation>;
export type CreatePostCommentMutationResult = Apollo.MutationResult<CreatePostCommentMutation>;
export type CreatePostCommentMutationOptions = Apollo.BaseMutationOptions<CreatePostCommentMutation, CreatePostCommentMutationVariables>;