/* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/


import { AddIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import useShowToast from "../hooks/useShowToast";
import ReactPlayer from "react-player";
import { LiaCameraRetroSolid } from "react-icons/lia";
import Autosuggest from "react-autosuggest";
import { useCreatePostMutation } from "./networking/__generated__/create-post-mutation.generated";
import { useTagsQuery } from "./networking/__generated__/tags-query.generated";
import sanitizeHtml from "sanitize-html";
import useUploadFiles, {
  LocalFileViewer,
  supportedMediaTypes,
} from "../utils/use-upload-files";

const MAX_CHAR = 500;

const CreateQueenBee = ({ tag }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [postText, setPostText] = useState("");
  const imageRef = useRef(null);
  const [remainingChar, setRemainingChar] = useState(MAX_CHAR);
  const showToast = useShowToast();
  const [loading, setLoading] = useState(false);
  const [isSmallScreen] = useMediaQuery(
    "(min-width: 375px) and (min-height: 667px)",
  );
  const [suggestions, setSuggestions] = useState([{ name: tag }]);
  const [originalSuggestions, setOriginalSuggestions] = useState([
    { name: tag },
  ]);
  const [value, setValue] = useState(tag);

  const [asset, setAsset] = useState();
  const [localFile, setLocalFile] = useState();
  const [progress, setProgress] = useState(0);
  const { upload } = useUploadFiles();

  const handleTextChange = (e) => {
    const inputText = e.target.value;

    if (inputText.length > MAX_CHAR) {
      const truncatedText = inputText.slice(0, MAX_CHAR);
      setPostText(truncatedText);
      setRemainingChar(0);
    } else {
      setPostText(inputText);
      setRemainingChar(MAX_CHAR - inputText.length);
    }
  };

  const [createPostAPI] = useCreatePostMutation();
  const { data: tagsData, error: tagsError } = useTagsQuery({
    variables: {
      limit: 1000,
    },
    fetchPolicy: "cache-first",
  });
  const allTags = tagsData?.tags.edges.map(({ node }) => node) || [];

  useEffect(() => {
    if (!tagsError) {
      return;
    }

    showToast("Error", tagsError.message, "error");
  }, [tagsError]);

  const handleCreatePost = async () => {
    setLoading(true);
    try {
      const tagId = allTags.find(
        (t) => t.name.toLowerCase() === tag.toLowerCase(),
      )?.id;

      if (!tagId) {
        showToast(
          "Error",
          "Tag id not found, please refresh the page and try again",
          "error",
        );
        return;
      }

      await createPostAPI({
        variables: {
          input: {
            text: sanitizeHtml(postText),
            scheduledAt: new Date(),
            tagIds: [tagId],
            assetIds: asset ? [asset.id] : [],
          },
        },
        refetchQueries: ["Timeline"],
      });

      onClose();
      setPostText("");
      setLocalFile(undefined);
      setAsset(undefined);
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const getSuggestions = (value) => {
    const tags = value.split(",");
    const lastTag = tags[tags.length - 1].trim();
    const inputValue = lastTag.toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : originalSuggestions.filter((suggestion) =>
          suggestion.name.toLowerCase().includes(inputValue),
        );
  };

  const getSuggestionValue = (suggestion) => {
    const tagsArray = value.split(",");
    tagsArray[tagsArray.length - 1] = suggestion.name;
    // setTags(tagsArray);
    return `${tagsArray.join(", ")}, `;
  };
  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  const inputProps = {
    placeholder: "Tags",
    value,
    onChange: (event, { newValue }) => {
      if (!newValue.includes(tag)) {
        return;
      }
      setValue(newValue);
    },
    style: {
      fontFamily: "Orbitron, sans-serif",
      backgroundColor: "black",
      color: "white",
      border: "1px solid white",
      borderRadius: "5px",
      width: "400px",
      height: "40px",
    },
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    console.log("onSuggestionsFetchRequested called with value:", value);
    setSuggestions(getSuggestions(value));
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderInputComponent = (inputProps) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span style={{ fontSize: "24px", marginRight: "10px" }}>#</span>
      <input {...inputProps} />
    </div>
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleCreatePost();
    }
  };

  const handleMediaChange = async (event) => {
    const fileList = event.target.files;

    if (!fileList) {
      return;
    }

    const files = Array.from(fileList);
    const file = files[0];

    setAsset(undefined);
    setProgress(0);
    setLocalFile(file);
    setLoading(true);

    try {
      const asset = await upload(file, setProgress);
      setAsset(asset);
    } catch (error) {
      showToast("Error", error.message, "error");
      setLocalFile(undefined);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        position={"fixed"}
        bottom={10}
        right={isSmallScreen ? "50" : "5"}
        bg="gray.700"
        onClick={onOpen}
        size={{ base: "sm", sm: "md", md: "lg" }}
        border="2px"
        borderColor="yellow.400"
      >
        <AddIcon color="yellow.400" />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <ModalContent
          backgroundColor="black"
          border="1px"
          borderColor="yellow.400"
        >
          <ModalHeader
            fontFamily={"Orbitron, sans-serif"}
            textAlign="center"
            color="yellow.400"
          >
            Create Post
          </ModalHeader>
          <ModalBody pb={6}>
            <FormControl>
              <Textarea
                placeholder="Post content goes here"
                fontFamily={"Orbitron, sans-serif"}
                color="yellow.400"
                backgroundColor="black"
                onChange={handleTextChange}
                onKeyDown={handleKeyDown} // Use onKeyDown here
                value={postText}
              />
              <Box marginTop="4">
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  getSuggestionValue={getSuggestionValue}
                  renderSuggestion={renderSuggestion}
                  inputProps={inputProps}
                  renderInputComponent={renderInputComponent}
                />
              </Box>

              <Text
                fontSize="xs"
                fontWeight="bold"
                textAlign={"right"}
                m={"1"}
                color={"gray.800"}
              >
                {remainingChar}/{MAX_CHAR}
              </Text>

              <Input
                type="file"
                hidden
                ref={imageRef}
                onChange={handleMediaChange}
                accept={supportedMediaTypes.join(", ")}
              />
              <LiaCameraRetroSolid
                style={{ marginLeft: "5px", cursor: "pointer" }}
                size={24}
                color="#FF6EC7"
                onClick={() => imageRef.current.click()}
              />
            </FormControl>

            {localFile && (
              <Flex mt={5} w={"full"} position={"relative"}>
                <LocalFileViewer localFile={localFile} />
                <CloseButton
                  onClick={() => {
                    setAsset(undefined);
                    setLocalFile(undefined);
                  }}
                  bg={"gray.800"}
                  position={"absolute"}
                  top={2}
                  right={2}
                />
              </Flex>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              onClick={handleCreatePost}
              isLoading={loading}
              fontFamily={"Orbitron, sans-serif"}
              color="yellow.400"
            >
              Post
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateQueenBee
