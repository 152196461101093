import React, { createContext, useEffect, useMemo, useState } from "react";
import { socket } from "./socket";
import useHandleInboundEvent from "./useHandleInboundEvent";
import { socketInboundEventSubscriptionNames } from "./models";
import Session from "supertokens-auth-react/recipe/session";
import useSendEvent from "./useSendEvent";
import { useMe } from "../providers/me-provider";

const Context = createContext({});

export const WebSocketProvider = () => {
  const [isConnected, setIsConnected] = useState(false);
  const handleInboundEvent = useHandleInboundEvent();
  const sendEvent = useSendEvent();
  const { me } = useMe();

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
    };

    const onDisconnect = () => {
      setIsConnected(false);
    };

    // Already connected
    if (socket.connected) {
      onConnect();
    }

    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);

    socketInboundEventSubscriptionNames.forEach((eventName) => {
      socket.on(eventName, (data) => {
        handleInboundEvent(eventName, data);
      });
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [handleInboundEvent]);

  useEffect(() => {
    if (!isConnected || !me) {
      return;
    }

    const authenticate = async () => {
      if (!(await Session.doesSessionExist())) {
        return;
      }
      const token = await Session.getAccessToken();
      if (!token) {
        return;
      }

      sendEvent("auth", { token });
    };
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, me]);

  const value = useMemo(() => ({}), []);
  return <Context.Provider value={value} />;
};
