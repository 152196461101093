import { useEffect } from "react";
import { useMe } from "../providers/me-provider";
import { useLikePostMutation } from "./networking/__generated__/like-post-mutation.generated";
import { useUnlikePostMutation } from "./networking/__generated__/unlike-post-mutation.generated";
import useShowToast from "./useShowToast";

const useToggleLikePost = ({
  post: { id, liked, likeCount },
}: {
  post: { id: string; liked: boolean; likeCount: number };
}) => {
  const { me } = useMe();
  const showToast = useShowToast();
  const [likePostAPI, { loading: likePostLoading, error: likePostError }] =
    useLikePostMutation({
      variables: {
        id,
      },
      optimisticResponse: {
        post: {
          id,
          likeCount: likeCount + 1,
          liked: true,
        },
      },
    });
  const [
    unlikePostAPI,
    { loading: unlikePostLoading, error: unlikePostError },
  ] = useUnlikePostMutation({
    variables: {
      id,
    },
    optimisticResponse: {
      post: {
        id,
        likeCount: likeCount - 1,
        liked: false,
      },
    },
  });
  const error = likePostError || unlikePostError;
  const loading = likePostLoading || unlikePostLoading;

  useEffect(() => {
    if (!error) {
      return;
    }

    showToast("Error", error.message, "error");
  }, [error]);

  const toggleLikePost = async () => {
    if (!me) {
      return showToast(
        "Error",
        "You need to be logged in to like a post",
        "error",
      );
    }

    if (loading) {
      return;
    }

    if (liked) {
      unlikePostAPI();
    } else {
      likePostAPI();
    }
  };

  return toggleLikePost;
};

export default useToggleLikePost;
