import { useEffect } from "react";
import { useMe } from "../providers/me-provider";
import useShowToast from "./useShowToast";
import { useFollowUserMutation } from "./networking/__generated__/follow-user-mutation.generated";
import { useFollowingQuery } from "./networking/__generated__/following-query.generated";
import { useUnfollowUserMutation } from "./networking/__generated__/unfollow-user-mutation.generated";

const useToggleFollowUser = ({ userId }: { userId: string }) => {
  const { me } = useMe();
  const showToast = useShowToast();
  const { data: followingData, error: followingError } = useFollowingQuery({
    variables: {
      limit: 100,
    },
    fetchPolicy: "cache-first",
  });

  const [
    followUserAPI,
    { loading: followUserLoading, error: followUserError },
  ] = useFollowUserMutation({
    variables: {
      id: userId,
    },
    refetchQueries: ["Following", "Followers", "FollowersCount"],
  });
  const [
    unfollowUserAPI,
    { loading: unfollowUserLoading, error: unfollowUserError },
  ] = useUnfollowUserMutation({
    variables: {
      id: userId,
    },
    refetchQueries: ["Following", "Followers", "FollowersCount"],
  });
  const error = followUserError || unfollowUserError;
  const loading = followUserLoading || unfollowUserLoading;

  useEffect(() => {
    if (!error && !followingError) {
      return;
    }

    showToast("Error", error?.message ?? followingError?.message, "error");
  }, [error, followingError]);

  const isFollowing = followingData?.following.edges.some(
    (edge) => edge.node.id === userId,
  );

  const toggleFollowUser = async () => {
    if (!me) {
      return showToast(
        "Error",
        "You need to be logged in to follow a user",
        "error",
      );
    }

    if (!followingData || loading) {
      return;
    }

    if (isFollowing) {
      unfollowUserAPI();
    } else {
      followUserAPI();
    }
  };

  return {
    toggleFollowUser,
    submitting: loading,
    isFollowingUser: isFollowing ?? false,
    following: followingData?.following.edges.map((edge) => edge.node) ?? [],
    totalFollowingCount: followingData?.following.pageInfo.totalEdges ?? 0,
  };
};

export default useToggleFollowUser;
