 /* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

import { Box, Container } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
 import UserPage from "./pages/UserPage"; 
import PostPage from "./pages/PostPage";
import Header from './components/Header'
import HomePage from "./pages/HomePage";
import FundingPage from "./pages/FundingPage";
import ScholarScoreboard from "./pages/ScholarScoreboard";
import UpdateProfilePage from "./pages/UpdateProfilePage";
import CreatePost from "./components/CreatePost";
import ChatPage from "./pages/ChatPage";
import { SettingsPage } from "./pages/SettingsPage";
import GlobalPage from "./pages/GlobalPage";
import PageLayout from "./components/Layouts/PageLayout";
import HiveMindHome from "./pages/HiveMindHome";
import HiveMindPage from "./pages/HiveMindPage";
import HiveMindComputer from './components/HiveMind/HiveMindComputer';
import HiveMindBiology from './components/HiveMind/HiveMindBiology';
import HiveMindChemistry from './components/HiveMind/HiveMindChemistry';
import HiveMindEarth from "./components/HiveMind/HiveMindEarth";
import HiveMindEngineering from "./components/HiveMind/HiveMindEngineering";
import HiveMindMath from "./components/HiveMind/HiveMindMath";
import HiveMindMedicine from "./components/HiveMind/HiveMindMedicine";
import HiveMindMisc from "./components/HiveMind/HiveMindMisc";
import HiveMindPhysics from "./components/HiveMind/HiveMindPhysics";
import TagsPage from "./pages/TagsPage";

import SuperTokens from "supertokens-auth-react";
import Passwordless from "supertokens-auth-react/recipe/passwordless";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { PasswordlessPreBuiltUI } from "supertokens-auth-react/recipe/passwordless/prebuiltui";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
import * as reactRouterDom from "react-router-dom";
import apolloClient from "./networking/apollo-client.js";
import { MeDocument } from "./providers/networking/__generated__/me-query.generated";
import { ApolloError } from "@apollo/client";
import OnboardingScene from "./scenes/onboarding";

SuperTokens.init({
  appInfo: {
    appName: import.meta.env.VITE_SUPERTOKENS_APP_NAME,
    apiDomain: import.meta.env.VITE_SUPERTOKENS_API_DOMAIN,
    websiteDomain: import.meta.env.VITE_SUPERTOKENS_WEBSITE_DOMAIN,
    apiBasePath: "/auth",
    websiteBasePath: "/auth",
  },
  style: `
    [data-supertokens~=container] {
      font-family: Orbitron, sans-serif;
      --palette-background: 0, 0, 0, 0;
      --palette-inputBackground: 0, 0, 0, 0;
      --palette-inputBorder: 41, 41, 41;
      --palette-textTitle: 255, 255, 255;
      --palette-textLabel: 255, 255, 255;
      --palette-textPrimary: 255, 255, 255;
      --palette-error: 173, 46, 46;
      --palette-textInput: 169, 169, 169;
      --palette-textLink: 169, 169, 169;
      --palette-primary: 135, 206, 250;
      --palette-primaryBorder: 135, 206, 250;
    }
    [data-supertokens~=button] {
      background-color: rgba(255,255,255,0.08);
      font-family: Orbitron, sans-serif;
      color: rgb(66,153,225);
    }
    [data-supertokens~=input] {
      font-family: Orbitron, sans-serif;
    }
    [data-supertokens~=superTokensBranding] {
      display: none;
    }
  `,
  recipeList: [
    Passwordless.init({
      contactMethod: "EMAIL",
    }),
    Session.init({
      tokenTransferMethod: "header",
    }),
  ],
  getRedirectionURL: async (context) => {
    if (context.action === "SUCCESS" && context.newSessionCreated) {
      try {
        await apolloClient.query({
          query: MeDocument,
        });

        if (context.redirectToPath !== undefined) {
          // we are navigating back to where the user was before they authenticated
          return context.redirectToPath;
        }
      } catch (error) {
        if (
          error instanceof ApolloError &&
          error.graphQLErrors.find((e) => e.message === "INVALID_USER")
        ) {
          return "/onboarding";
        }
      }
    }
  },
});

function App() {
  return (
    <Box position={"relative"} w="full">
      <Header mb={10} />
      <PageLayout>
        <Container maxW="620px">
          <Routes>
            {/* This renders the login UI on the /auth route */}
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
              PasswordlessPreBuiltUI,
            ])}
            <Route
              path="/"
              element={
                <SessionAuth>
                  <GlobalPage />
                  <CreatePost />
                </SessionAuth>
              }
            />
            <Route
              path="/home"
              element={
                <SessionAuth>
                  <HomePage />
                  <CreatePost />
                </SessionAuth>
              }
            />
            <Route
              path="/update"
              element={
                <SessionAuth>
                  <UpdateProfilePage />
                </SessionAuth>
              }
            />
            <Route
              path="/Rec"
              element={
                <SessionAuth>
                  <TagsPage />
                </SessionAuth>
              }
            />
            <Route
              path="/onboarding"
              element={
                <SessionAuth>
                  <OnboardingScene />
                </SessionAuth>
              }
            />
            <Route path="/:username" element={<UserPage />} />
            <Route path="/:username/post/:pid" element={<PostPage />} />
            <Route
              path="/chat"
              element={
                <SessionAuth>
                  <ChatPage />
                </SessionAuth>
              }
            />
            <Route
              path="/settings"
              element={
                <SessionAuth>
                  <SettingsPage />
                </SessionAuth>
              }
            />
            <Route
              path="/funding"
              element={
                <SessionAuth>
                  <FundingPage />
                </SessionAuth>
              }
            />
            <Route
              path="/scholarscores"
              element={
                <SessionAuth>
                  <ScholarScoreboard />
                </SessionAuth>
              }
            />
            <Route
              path="/Hivemenu"
              element={
                <SessionAuth>
                  <HiveMindHome />
                </SessionAuth>
              }
            />
            <Route
              path="tags/:tags"
              element={
                <SessionAuth>
                  <HiveMindPage />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindBiology"
              element={
                <SessionAuth>
                  <HiveMindBiology />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindChemistry"
              element={
                <SessionAuth>
                  <HiveMindChemistry />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindComputer"
              element={
                <SessionAuth>
                  <HiveMindComputer />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindEarth"
              element={
                <SessionAuth>
                  <HiveMindEarth />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindEngineering"
              element={
                <SessionAuth>
                  <HiveMindEngineering />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindMath"
              element={
                <SessionAuth>
                  <HiveMindMath />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindMedicine"
              element={
                <SessionAuth>
                  <HiveMindMedicine />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindMisc"
              element={
                <SessionAuth>
                  <HiveMindMisc />
                </SessionAuth>
              }
            />
            <Route
              path="HiveMindPhysics"
              element={
                <SessionAuth>
                  <HiveMindPhysics />
                </SessionAuth>
              }
            />
          </Routes>
        </Container>
      </PageLayout>
    </Box>
  );
}
  
  export default App
