import React, { useState } from 'react';
import { Box, Text, Button, Input, Collapse, IconButton, Tooltip } from '@chakra-ui/react';
import { MdAddBox } from 'react-icons/md';
import { HiAcademicCap } from 'react-icons/hi';
import { useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMe } from "../providers/me-provider";
import { useUpdateSkillsMutation } from "../pages/networking/__generated__/update-skills-mutation.generated";
import { useUpdateProjectsMutation } from "../pages/networking/__generated__/update-projects-mutation.generated";
import { useUpdateEducationsMutation } from "../pages/networking/__generated__/update-educations-mutation.generated";

const BioSidebar = ({ user, showToast }) => {
  const [inputs, setInputs] = useState({
    skill: "",
    project: "",
    education: "",
  });

  const [currentField, setCurrentField] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSmallScreen] = useMediaQuery(
    "(min-width: 375px) and (min-height: 667px)",
  );
  const [isIpadEsq] = useMediaQuery(
    "(min-width: 768px) and (min-height: 1024px)",
  );
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const { me: currentUser } = useMe();
  const [updateSkillsAPI] = useUpdateSkillsMutation();
  const [updateProjectsAPI] = useUpdateProjectsMutation();
  const [updateEducationsAPI] = useUpdateEducationsMutation();

  const location = useLocation();
  const navigate = useNavigate();

  const handleAddClick = (field) => {
    setCurrentField(field);
  };

  const handleInputChange = (e) => {
    setInputs({
      ...inputs,
      [currentField]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      switch (currentField) {
        case "skill":
          await updateSkillsAPI({
            variables: {
              skills: [...currentUser.skills, inputs.skill.trim()],
            },
          });
          break;
        case "project":
          await updateProjectsAPI({
            variables: {
              projects: [...currentUser.projects, inputs.project.trim()],
            },
          });
          break;
        case "education":
          await updateEducationsAPI({
            variables: {
              educations: [...currentUser.educations, inputs.education.trim()],
            },
          });
          break;
        default:
          break;
      }
      showToast("Success", "Profile Updated", "success");
      setIsOpenSidebar(false);
      navigate(`/${data.username}`);
    } catch (error) {
      showToast("Error", error.message || "An unknown error occurred", "error");
    }
  };

  const handleInputSubmit = async () => {
    if (inputs[currentField].trim() !== "") {
      await handleSubmit();
      setInputs({
        ...inputs,
        [currentField]: "",
      });
      setCurrentField("");
    }
  };

  const closeSidebar = () => {
    setIsOpenSidebar(false);
  };

  const isOwner =
    currentUser?.id === user.id &&
    location.pathname.includes(`/${user.username}`);

  return (
    <aside>
      <Tooltip backgroundColor="black" color="gray.500" fontSize="md">
        <IconButton
          aria-label="Toggle sidebar"
          icon={<HiAcademicCap color="rgb(135,206,250)" />}
          onClick={() => setIsOpenSidebar(!isOpenSidebar)}
          position="fixed"
          top="50%"
          right={0}
          color="rgb(135, 206, 250)"
          _hover={{ borderColor: "yellow.400", borderWidth: "1px" }}
          border="1px solid"
          borderColor="rgb(135,206,250)"
        />
      </Tooltip>

      <Collapse in={isOpenSidebar}>
        <Box
          height={"75vh"}
          border={"1px solid"}
          borderRadius={"lg"}
          borderColor={"gray.400"}
          w={isIpadEsq ? "400px" : { base: "95%", sm: 230 }}
          position={"fixed"}
          top={150}
          right={0}
          backgroundColor="black"
          zIndex={9998}
          p={4}
          overflow="auto" // Enable scrolling if content overflows
        >
          <Box
            fontFamily={"Orbitron, sans-serif"}
            color={"rgb(135, 206, 250)"}
            mb={4}
          >
            Credentials
          </Box>
          <Text
            color={"rgb(135, 206, 250)"}
            fontFamily={"Orbitron, sans-serif"}
          >
            Full Name
          </Text>
          <Text
            fontSize={"sm"}
            mr={10}
            fontFamily={"Orbitron, sans-serif"}
            mt={isIpadEsq ? -10 : 0}
          >
            {user?.displayName || (
              <Text as="span" color="white">
                N/A
              </Text>
            )}
          </Text>
          <Text
            color={"rgb(135, 206, 250)"}
            fontFamily={"Orbitron, sans-serif"}
          >
            Bio
          </Text>
          <Text
            fontSize={"sm"} // Adjust font size if needed
            mr={10}
            color={"gray.200"}
            fontFamily={"Orbitron, sans-serif"}
            mt={isIpadEsq ? 0 : isSmallScreen ? 0 : 0}
            whiteSpace="normal" // Ensure text wraps normally
            wordBreak="break-word" // Break words if they are too long
            lineHeight="1.5" // Set a line height for better readability
          >
            {user?.about || (
              <Text as="span" color="white">
                N/A
              </Text>
            )}
          </Text>
          <Text
            color={"rgb(135, 206, 250)"}
            fontFamily={"Orbitron, sans-serif"}
          >
            Skills
          </Text>
          <Text color={"white"} fontFamily={"Orbitron, sans-serif"}>
            {user?.skills.join(", ") || (
              <Text as="span" color="white">
                N/A
              </Text>
            )}
          </Text>
          {isOwner && <MdAddBox onClick={() => handleAddClick("skill")} />}
          {currentField === "skill" && (
            <Box mt={2}>
              <Input
                value={inputs.skill}
                onChange={(e) =>
                  setInputs({ ...inputs, skill: e.target.value })
                }
                placeholder="Add new skill"
                mb={2}
                fontFamily={"Orbitron, sans-serif"}
              />
              <Button
                onClick={handleInputSubmit}
                colorScheme="blue"
                fontFamily={"Orbitron, sans-serif"}
              >
                Add
              </Button>
            </Box>
          )}
          <Text
            color={"rgb(135, 206, 250)"}
            fontFamily={"Orbitron, sans-serif"}
          >
            Previous Projects
          </Text>
          <Text color={"white"} fontFamily={"Orbitron, sans-serif"}>
            {user?.projects.join(", ") || (
              <Text as="span" color="white">
                N/A
              </Text>
            )}
          </Text>
          {isOwner && <MdAddBox onClick={() => handleAddClick("project")} />}
          {currentField === "project" && (
            <Box mt={2}>
              <Input
                value={inputs.project}
                onChange={(e) =>
                  setInputs({ ...inputs, project: e.target.value })
                }
                placeholder="Add new project"
                mb={2}
                fontFamily={"Orbitron, sans-serif"}
              />
              <Button
                onClick={handleInputSubmit}
                colorScheme="blue"
                fontFamily={"Orbitron, sans-serif"}
              >
                Add
              </Button>
            </Box>
          )}
          <Text
            color={"rgb(135, 206, 250)"}
            fontFamily={"Orbitron, sans-serif"}
          >
            Education
          </Text>
          <Text color={"white"} fontFamily={"Orbitron, sans-serif"}>
            {user?.educations.join(", ") || (
              <Text as="span" color="white">
                N/A
              </Text>
            )}
          </Text>
          {isOwner && <MdAddBox onClick={() => handleAddClick("education")} />}
          {currentField === "education" && (
            <Box mt={2}>
              <Input
                value={inputs.education}
                onChange={(e) =>
                  setInputs({ ...inputs, education: e.target.value })
                }
                placeholder="Add education"
                mb={2}
                fontFamily={"Orbitron, sans-serif"}
              />
              <Button
                onClick={handleInputSubmit}
                colorScheme="blue"
                fontFamily={"Orbitron, sans-serif"}
              >
                Add
              </Button>
            </Box>
          )}
          <Button
            fontFamily={"Orbitron, sans-serif"}
            colorScheme="blue"
            mt={4}
            onClick={closeSidebar}
          >
            Close
          </Button>
        </Box>
      </Collapse>
    </aside>
  );
};

export default BioSidebar;