import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { MessageFragmentFragmentDoc } from './message-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConversationMessagesQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type ConversationMessagesQuery = { __typename?: 'Query', conversationMessages: { __typename?: 'ConversationMessagesConnection', pageInfo: { __typename?: 'LimitOffsetPageInfo', totalEdges: number, offset: number, limit: number, hasPreviousPage: boolean, hasNextPage: boolean }, edges: Array<{ __typename?: 'ConversationMessagesEdge', node: { __typename?: 'Message', id: string, text?: string | null, userId: string, createdAt: any, assets: Array<{ __typename?: 'Asset', id: string, url: string, type: Types.AssetType }> } }> } };


export const ConversationMessagesDocument = gql`
    query ConversationMessages($id: String!, $limit: Int, $offset: Int) {
  conversationMessages(id: $id, limit: $limit, offset: $offset) {
    pageInfo {
      totalEdges
      offset
      limit
      hasPreviousPage
      hasNextPage
    }
    edges {
      node {
        ...MessageFragment
      }
    }
  }
}
    ${MessageFragmentFragmentDoc}`;

/**
 * __useConversationMessagesQuery__
 *
 * To run a query within a React component, call `useConversationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useConversationMessagesQuery(baseOptions: Apollo.QueryHookOptions<ConversationMessagesQuery, ConversationMessagesQueryVariables> & ({ variables: ConversationMessagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationMessagesQuery, ConversationMessagesQueryVariables>(ConversationMessagesDocument, options);
      }
export function useConversationMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationMessagesQuery, ConversationMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationMessagesQuery, ConversationMessagesQueryVariables>(ConversationMessagesDocument, options);
        }
export function useConversationMessagesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConversationMessagesQuery, ConversationMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConversationMessagesQuery, ConversationMessagesQueryVariables>(ConversationMessagesDocument, options);
        }
export type ConversationMessagesQueryHookResult = ReturnType<typeof useConversationMessagesQuery>;
export type ConversationMessagesLazyQueryHookResult = ReturnType<typeof useConversationMessagesLazyQuery>;
export type ConversationMessagesSuspenseQueryHookResult = ReturnType<typeof useConversationMessagesSuspenseQuery>;
export type ConversationMessagesQueryResult = Apollo.QueryResult<ConversationMessagesQuery, ConversationMessagesQueryVariables>;