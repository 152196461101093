/* Gegrüßet seist du, Maria, voll der Gnade der Herr ist mit dir. Du bist gebenedeit unter den Frauen,
und gebenedeit ist die Frucht deines Leibes, Jesus Heilige Maria, Mutter Gottes bitte für uns Sünder
jetzt und in der Stunde unseres Todes Amen
*/

import React from 'react'
import {
  Box,
  Button,
  Flex,
  Input,
  Text,
  Skeleton,
  SkeletonCircle,
} from "@chakra-ui/react";
import { SearchIcon } from '@chakra-ui/icons'
import Conversation from "../components/Conversation";
import MessageContainer from "../components/MessageContainer";
import { useEffect, useState } from "react";
import useShowToast from "../hooks/useShowToast";
import { css } from "@emotion/react";
import { useMediaQuery } from "@chakra-ui/react";
import { useConversationsQuery } from "./networking/__generated__/conversations-query.generated";
import { useSearchParams } from "react-router-dom";
import { useUserByUsernameLazyQuery } from "../hooks/networking/__generated__/user-by-username-query.generated";
import { useCreateConversationMutation } from "./networking/__generated__/create-conversation-mutation.generated";
import { useMessageSummaryQuery } from "./networking/__generated__/message-summary-query.generated";
import { useMarkHasNoNewMessagesMutation } from "./networking/__generated__/mark-has-no-new-messages-mutation.generated";

const ChatPage = () => {
  const showToast = useShowToast();
  const [rawSearchText, setRawSearchText] = useState("");
  const [ipadPro] = useMediaQuery(
    "(min-width: 1024px) and (min-height: 1366px)",
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedConversationId = searchParams.get("id");

  const {
    data: conversationsData,
    error: conversationsError,
    loading: conversationsLoading,
    refetch: refetchConversations,
  } = useConversationsQuery({
    variables: { limit: 20 },
    fetchPolicy: "cache-first",
  });

  const { data: messageSummaryData } = useMessageSummaryQuery({
    fetchPolicy: "cache-first",
  });
  const [markHasNoNewMessagesAPI, { error: markHasNoNewMessagesError }] =
    useMarkHasNoNewMessagesMutation();

  const [isSearching, setIsSearching] = useState(false);
  const [userByUsernameAPI] = useUserByUsernameLazyQuery();
  const [createConversationAPI] = useCreateConversationMutation();

  const hideScrollBar = css`
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  `;

  useEffect(() => {
    if (messageSummaryData?.messageSummary.hasNewMessages === true) {
      markHasNoNewMessagesAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageSummaryData?.messageSummary.hasNewMessages]);

  useEffect(() => {
    const error = conversationsError || markHasNoNewMessagesError;

    if (!error) {
      return;
    }

    showToast("Error", error.message, "error");
  }, [conversationsError, markHasNoNewMessagesError]);

  const handleConversationSearch = async (e) => {
    e.preventDefault();

    if (!rawSearchText || isSearching) {
      return;
    }

    setIsSearching(true);

    try {
      const { data: userData } = await userByUsernameAPI({
        variables: {
          username: rawSearchText,
        },
      });

      const userId = userData?.user?.id;
      if (!userId) {
        throw new Error("User not found.");
      }

      const { data: conversationData } = await createConversationAPI({
        variables: {
          recipientUserId: userId,
        },
      });

      await refetchConversations();

      setSearchParams({ id: conversationData.conversation.id });
    } catch (error) {
      showToast("Error", error.message, "error");
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <Box
      position={"absolute"}
      left={"50%"}
      width={{ lg: "750px", md: "80%", base: "100%" }}
      p={4}
      transform={"translateX(-50%)"}
    >
      <Flex
        flex={30}
        gap={2}
        flexDirection={"column"}
        maxW={{ sm: "250px", md: "full" }}
        mx={"auto"}
      >
        <Flex justifyContent={"center"} alignItems={"center"}>
          <form onSubmit={handleConversationSearch}>
            <Flex alignItems={"center"} gap={2}>
              <Input
                placeholder="Search for a user"
                value={rawSearchText}
                onChange={(e) => setRawSearchText(e.target.value)}
                fontFamily={"Orbitron, sans-serif"}
              />
              <Button
                size={"sm"}
                onClick={handleConversationSearch}
                isLoading={isSearching}
              >
                <SearchIcon />
              </Button>
            </Flex>
          </form>
        </Flex>

        {/* The Users*/}
        <Box width="95%" overflowX="auto" css={hideScrollBar} ml={7}>
          <Flex flexDirection="row" flexWrap="nowrap">
            {!conversationsData &&
              [0, 1, 2, 3].map((_, i) => (
                <Flex
                  key={i}
                  gap={4}
                  alignItems={"center"}
                  p={"1"}
                  borderRadius={"md"}
                >
                  <Box>
                    <SkeletonCircle size={"10"} />
                  </Box>
                  <Flex w={"full"} flexDirection={"column"} gap={3}>
                    <Skeleton h={"10px"} w={"80px"} />
                    <Skeleton h={"8px"} w={"90%"} />
                  </Flex>
                </Flex>
              ))}

            {conversationsData?.conversations.edges.map(
              ({ node: conversation }) => (
                <Conversation
                  key={conversation.id}
                  isOnline={
                    conversation.otherParticipants[0].presence === "ONLINE"
                  }
                  conversation={conversation}
                />
              ),
            )}

            {!!conversationsData &&
              conversationsData.conversations.edges.length === 0 && (
                <Text>No conversations.</Text>
              )}
          </Flex>
        </Box>

        {/* The Convo*/}
        {!!selectedConversationId && (
          <Box
            style={{
              position: "absolute",
              top: ipadPro ? "110%" : "100%",
              left: "10%",
              width: "90%",
              height: ipadPro ? "100%" : "40%",
            }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <MessageContainer />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default ChatPage;
