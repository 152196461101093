/*שָלוֹם לָךְ, מִרְיָם,
מְלֵאַת הַחֶסֶד,
ה׳ עִמָּךְ,
בְּרוּכָה אַתְּ בַּנָשִׁים
וּבָרוּךְ פְּרִי בִטְנֵךְ יֵשׁוּעַ,
מִרְיָם הַקְּדוֹשָׁה,
אֵם הָאֱלֹהִים,
הִתְפַּלְּלִי בַּעֲדֵנוּ, הַחוֹטְאִים,
עַתָּה וּבִשְׁעַת מוֹתֵנוּ. */

import { Avatar, Box, Flex, Image, Text } from "@chakra-ui/react";
import { BsCheck2All } from "react-icons/bs";
import ReactPlayer from "react-player";
import { useMe } from "../providers/me-provider";
import { RemoteAssetViewer } from "../utils/use-upload-files";

const Message = ({ ownMessage, message, conversation }) => {
  const { me: user } = useMe();

  const asset = message.assets.length ? message.assets[0] : null;

  return (
    <>
      {ownMessage ? (
        <Flex gap={2} alignSelf={"flex-end"}>
          {message?.text && (
            <Flex bg={"black"} maxW={"350px"} p={1} borderRadius={"md"}>
              <Text
                color={"rgb(135, 206, 250)"}
                fontFamily={"Orbitron, sans-serif"}
              >
                {message?.text}
              </Text>
              <Box
                alignSelf={"flex-end"}
                ml={1}
                color={message?.read ? "blue.400" : ""}
                fontWeight={"bold"}
              >
                <BsCheck2All size={16} />
              </Box>
            </Flex>
          )}
          {asset && (
            <Box
              borderRadius={6}
              overflow={"hidden"}
              border={"1px solid white"}
              borderColor={"gray.light"}
            >
              <RemoteAssetViewer asset={asset} />
            </Box>
          )}
          <Avatar
            src={user.avatarAsset?.url}
            name={user.username}
            w="7"
            h={7}
          />
        </Flex>
      ) : (
        <Flex gap={2}>
          <Avatar
            src={conversation?.otherParticipants[0].avatarAsset?.url}
            name={conversation?.otherParticipants[0].username}
            w="7"
            h={7}
          />

          {message?.text && (
            <Text
              maxW={"350px"}
              bg={"black"}
              p={1}
              borderRadius={"md"}
              color={"orange"}
              fontFamily={"Orbitron, sans-serif"}
            >
              {message?.text}
            </Text>
          )}
          {asset && (
            <Box
              borderRadius={6}
              overflow={"hidden"}
              border={"1px solid white"}
              borderColor={"gray.light"}
            >
              <RemoteAssetViewer asset={asset} />
            </Box>
          )}
        </Flex>
      )}
    </>
  );
};

export default Message;
