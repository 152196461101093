export default function formatOrdinal(index: number): string {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = index % 100;

  // Special case for numbers ending in 11, 12, or 13
  if (remainder >= 11 && remainder <= 13) {
    return `${index}th`;
  }

  // General rule for other numbers
  const lastDigit = index % 10;
  return `${index}${suffixes[lastDigit] || "th"}`;
}
