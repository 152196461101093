import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
export type AssetFragmentFragment = { __typename?: 'Asset', id: string, type: Types.AssetType, url: string };

export const AssetFragmentFragmentDoc = gql`
    fragment AssetFragment on Asset {
  id
  type
  url
}
    `;