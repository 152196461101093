import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { PostFragmentFragmentDoc } from '../../../pages/networking/__generated__/post-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchPostsQueryVariables = Types.Exact<{
  query: Types.Scalars['String']['input'];
}>;


export type SearchPostsQuery = { __typename?: 'Query', posts: Array<{ __typename?: 'Post', id: string, text?: string | null, scheduledAt?: any | null, liked: boolean, likeCount: number, commentCount: number, user: { __typename?: 'User', id: string, username: string, displayName: string, avatarAsset?: { __typename?: 'Asset', id: string, url: string } | null }, assets: Array<{ __typename?: 'Asset', id: string, type: Types.AssetType, url: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> }> };


export const SearchPostsDocument = gql`
    query SearchPosts($query: String!) {
  posts: searchPosts(query: $query) {
    ...PostFragment
  }
}
    ${PostFragmentFragmentDoc}`;

/**
 * __useSearchPostsQuery__
 *
 * To run a query within a React component, call `useSearchPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPostsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchPostsQuery(baseOptions: Apollo.QueryHookOptions<SearchPostsQuery, SearchPostsQueryVariables> & ({ variables: SearchPostsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPostsQuery, SearchPostsQueryVariables>(SearchPostsDocument, options);
      }
export function useSearchPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPostsQuery, SearchPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPostsQuery, SearchPostsQueryVariables>(SearchPostsDocument, options);
        }
export function useSearchPostsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchPostsQuery, SearchPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchPostsQuery, SearchPostsQueryVariables>(SearchPostsDocument, options);
        }
export type SearchPostsQueryHookResult = ReturnType<typeof useSearchPostsQuery>;
export type SearchPostsLazyQueryHookResult = ReturnType<typeof useSearchPostsLazyQuery>;
export type SearchPostsSuspenseQueryHookResult = ReturnType<typeof useSearchPostsSuspenseQuery>;
export type SearchPostsQueryResult = Apollo.QueryResult<SearchPostsQuery, SearchPostsQueryVariables>;