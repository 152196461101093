 /* Dios te salve Maria, llena de gracia, el Señor is contigo, bendito tu eres, entre todas las mujeres,
y bendito es el fruto de tu Vientre Jesús, Santa Mara, Madre de Dios, ruega por nosotroa peccadores
ahora y la hora de nuestro muerto, amen*/

import {Box, Flex} from '@chakra-ui/react'
import Sidebar from '../../Sidebar/Sidebar'
import {useLocation} from 'react-router-dom'

const PageLayout = ({children}) => {

    const location = useLocation();

    if (location.pathname.startsWith("/auth")) {
      return <>{children}</>;
    }


    return (
      <Flex> 
        <Box w={{base:'70px', md:'240px'}}>
          <Sidebar />
        </Box>
        <Box flex={1} w={{base:'calc(100% - 70px)', md:'calc(100% - 240px)'}}>
          {children}
        </Box>
      </Flex>
    )
  }

export default PageLayout

/*import {Box, Flex} from '@chakra-ui/react'
import Sidebar from '../../Sidebar/Sidebar'
import {useLocation} from 'react-router-dom'

const PageLayout = ({children}) => {
    const {pathname} = useLocation()
  return (
    <Flex> 
      
        {pathname !== '/auth' ? (
            <Box w={{base:'70px', md:'240px'}}>
                <Sidebar />
            </Box>
        ) : null} 
   
        <Box flex={1} w={{base:'calc(100% - 70px)', md:'calc(100% - 240px)'}}>
            {children}
        </Box>
    </Flex>
  )
}

export default PageLayout  */