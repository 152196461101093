import { ApolloClient, InMemoryCache } from '@apollo/client';

const apolloClient = new ApolloClient({
  uri: import.meta.env.VITE_API_BASE_URL + "/graphql",
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          timeline: {
            keyArgs: ["followingOnly", "userId", "tagId"], // omit limit and offset
          },
          following: {
            keyArgs: ["userId"], // omit limit and offset
          },
          followers: {
            keyArgs: ["userId"], // omit limit and offset
          },
          postComments: {
            keyArgs: ["id"], // omit limit and offset
          },
          conversations: {
            keyArgs: ["filters", "query"], // omit limit and offset
          },
          conversationMessages: {
            keyArgs: ["id"], // omit limit and offset
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  headers: {
    "x-api-key": import.meta.env.VITE_API_KEY,
  },
});

export default apolloClient;