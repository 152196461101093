import React from "react";
import Props from "./FormErrorTextProps";
import { Text } from "@chakra-ui/react";

const FormErrorText = ({ children, ...rest }: Props) => (
  <Text
    fontFamily="Orbitron, sans-serif"
    textColor="red"
    fontSize="xs"
    {...rest}
  >
    {children}
  </Text>
);

export default FormErrorText;
