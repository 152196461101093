import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { MessageFragmentFragmentDoc } from './message-fragment.generated';
export type ConversationFragmentFragment = { __typename?: 'Conversation', id: string, favorited: boolean, read: boolean, sortBy: any, otherParticipants: Array<{ __typename?: 'User', id: string, displayName: string, username: string, presence: Types.Presence, avatarAsset?: { __typename?: 'Asset', url: string, id: string } | null }>, lastMessage?: { __typename?: 'Message', id: string, text?: string | null, userId: string, createdAt: any, assets: Array<{ __typename?: 'Asset', id: string, url: string, type: Types.AssetType }> } | null };

export const ConversationFragmentFragmentDoc = gql`
    fragment ConversationFragment on Conversation {
  id
  favorited
  read
  sortBy
  otherParticipants {
    id
    displayName
    username
    presence
    avatarAsset {
      url
      id
    }
  }
  lastMessage {
    ...MessageFragment
  }
}
    ${MessageFragmentFragmentDoc}`;