/* Χαῖρε Μαρία κεχαριτωμένη ὁ Κύριος μετά σοῦ Ἐυλογημένη σὺ ἐν γυναιξὶ, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας 
σοῦ Ἰησούς Ἁγία Μαρία, μῆτερ θεοῦ προσεύχου ὑπέρ ἡμῶν τῶν ἁμαρτωλῶν, νῦν καὶ ἐν τῇ ὥρᾳ τοῦ θανάτου ἡμῶν. */

import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import useShowToast from '../hooks/useShowToast'
import Post from "../components/Post";
import SidebarSuggested from '../components/Suggested/SidebarSuggested'
import { useMediaQuery } from "@chakra-ui/react";
import { useDisclosure } from '@chakra-ui/react';
import { useTimelineQuery } from "./networking/__generated__/timeline-query.generated";

const HomePage = () => {
  const { data, error, loading } = useTimelineQuery({
    variables: {
      followingOnly: true,
    },
  });
  const posts = data?.timeline.edges.map((edge) => edge.node) || [];
  const showToast = useShowToast();
  const [isSmallScreen] = useMediaQuery(
    "(min-width: 375px) and (min-height: 667px)",
  );
  const [isIphone] = useMediaQuery([
    "(min-device-width: 375px) and (max-device-width: 430px) and (min-device-height: 812px) and (max-device-height: 926px) and (-webkit-device-pixel-ratio: 3)",
  ]);
  const [isIpadEsq] = useMediaQuery(
    "(min-width: 768px) and (min-height: 1024px)",
  );
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    if (!error) {
      return;
    }

    showToast("Error", error.message, "error");
  }, [error]);

  return (
    <Flex>
      {/* This is the Right */}
      <Box
        flex={100}
        ml={isIpadEsq ? -150 : isIphone ? -75 : isSmallScreen ? -59.5 : -150}
      >
        {!loading && posts.length === 0 && (
          <Flex height="1vh" ml={225}>
            <Text
              color={"yellow.400"}
              fontFamily={"Orbitron, sans-serif"}
              fontSize="xl"
              marginLeft={isSmallScreen ? "-180" : "auto"}
            >
              Follow some Users!
            </Text>
          </Flex>
        )}
        {loading && (
          <Flex justify="center">
            <Spinner size="xl" />
          </Flex>
        )}

        {posts.map((post) => (
          <Box key={post.id} className="post">
            <Post post={post} />
          </Box>
        ))}
      </Box>

      <Box flex={20}>
        <SidebarSuggested />
      </Box>
    </Flex>
  );
};

export default HomePage