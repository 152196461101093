import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
export type PostFragmentFragment = { __typename?: 'Post', id: string, text?: string | null, scheduledAt?: any | null, liked: boolean, likeCount: number, commentCount: number, user: { __typename?: 'User', id: string, username: string, displayName: string, avatarAsset?: { __typename?: 'Asset', id: string, url: string } | null }, assets: Array<{ __typename?: 'Asset', id: string, type: Types.AssetType, url: string }>, tags: Array<{ __typename?: 'Tag', id: string, name: string }> };

export const PostFragmentFragmentDoc = gql`
    fragment PostFragment on Post {
  id
  text
  scheduledAt
  liked
  likeCount
  commentCount
  user {
    id
    username
    displayName
    avatarAsset {
      id
      url
    }
  }
  assets {
    id
    type
    url
  }
  tags {
    id
    name
  }
}
    `;