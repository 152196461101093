import * as Types from '../../../../__generated__/graphql-types.generated';

import { gql } from '@apollo/client';
import { ConversationFragmentFragmentDoc } from './conversation-fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateConversationMutationVariables = Types.Exact<{
  recipientUserId: Types.Scalars['String']['input'];
}>;


export type CreateConversationMutation = { __typename?: 'Mutation', conversation?: { __typename?: 'Conversation', id: string, favorited: boolean, read: boolean, sortBy: any, otherParticipants: Array<{ __typename?: 'User', id: string, displayName: string, username: string, presence: Types.Presence, avatarAsset?: { __typename?: 'Asset', url: string, id: string } | null }>, lastMessage?: { __typename?: 'Message', id: string, text?: string | null, userId: string, createdAt: any, assets: Array<{ __typename?: 'Asset', id: string, url: string, type: Types.AssetType }> } | null } | null };


export const CreateConversationDocument = gql`
    mutation CreateConversation($recipientUserId: String!) {
  conversation: createConversation(recipientUserId: $recipientUserId) {
    ...ConversationFragment
  }
}
    ${ConversationFragmentFragmentDoc}`;
export type CreateConversationMutationFn = Apollo.MutationFunction<CreateConversationMutation, CreateConversationMutationVariables>;

/**
 * __useCreateConversationMutation__
 *
 * To run a mutation, you first call `useCreateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMutation, { data, loading, error }] = useCreateConversationMutation({
 *   variables: {
 *      recipientUserId: // value for 'recipientUserId'
 *   },
 * });
 */
export function useCreateConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConversationMutation, CreateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CreateConversationDocument, options);
      }
export type CreateConversationMutationHookResult = ReturnType<typeof useCreateConversationMutation>;
export type CreateConversationMutationResult = Apollo.MutationResult<CreateConversationMutation>;
export type CreateConversationMutationOptions = Apollo.BaseMutationOptions<CreateConversationMutation, CreateConversationMutationVariables>;