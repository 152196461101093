/* Χαῖρε Μαρία κεχαριτωμένη ὁ Κύριος μετά σοῦ Ἐυλογημένη σὺ ἐν γυναιξὶ, καὶ εὐλογημένος ὁ καρπὸς τῆς κοιλίας 
σοῦ Ἰησούς Ἁγία Μαρία, μῆτερ θεοῦ προσεύχου ὑπέρ ἡμῶν τῶν ἁμαρτωλῶν, νῦν καὶ ἐν τῇ ὥρᾳ τοῦ θανάτου ἡμῶν. */

import { FaRegCommentAlt } from "react-icons/fa";
import { Box, Flex, Input, Text, Tooltip, useDisclosure, Button, Modal, ModalOverlay, ModalContent, ModalHeader, 
  ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react'
import React, { useState, useEffect } from 'react'
import Comment from '../Comment'; 
import useShowToast from "../../hooks/useShowToast";
import sanitizeHtml from "sanitize-html";
import { useMe } from "../../providers/me-provider";
import { usePostCommentsQuery } from "./networking/__generated__/post-comments-query.generated";
import { useCreatePostCommentMutation } from "./networking/__generated__/create-post-comment-mutation.generated";

const Com = ({ post }) => {
  const [isReplying, setIsReplying] = useState(false);
  const [reply, setReply] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = useState("xl");
  const { me: user } = useMe();
  const showToast = useShowToast();
  const [replyId, setReplyId] = useState(null);

  const { data: postCommentsData, error: postCommentsError } =
    usePostCommentsQuery({
      variables: {
        id: post.id,
        limit: 100,
      },
      skip: !isOpen,
    });

  const [createPostCommentAPI] = useCreatePostCommentMutation();

  useEffect(() => {
    if (!postCommentsError) {
      return;
    }

    showToast("Error", postCommentsError.message, "error");
  }, [postCommentsError]);

  // Inside Com component

  useEffect(() => {
    const modalOpen = localStorage.getItem(`modalOpen-${post.id}`) === "true";
    if (modalOpen) {
      onOpen();
    }
  }, [onOpen, post.id]); // Include post.id in the dependency array

  // Adjust handleOpen and handleClose to use the comment's ID
  const handleOpen = () => {
    localStorage.setItem(`modalOpen-${post.id}`, "true");
    onOpen();
  };

  const handleClose = () => {
    localStorage.setItem(`modalOpen-${post.id}`, "false"); // Set to 'false' instead of removing to ensure correct state tracking
    onClose();
  };

  const handleReply = async () => {
    if (!user)
      return showToast(
        "Error",
        "You need to be logged in to reply to a post",
        "error",
      );
    if (isReplying) return;
    setIsReplying(true);
    try {
      const sanitizedReply = sanitizeHtml(reply);
      await createPostCommentAPI({
        variables: {
          input: {
            postId: post.id,
            text: sanitizedReply,
            parentId: replyId,
          },
        },
        refetchQueries: ["PostComments"],
      });

      setReply(""); // Clear the reply input field after successful submission

      // Refresh the page after successful reply
      // window.location.reload();
    } catch (error) {
      console.log("Error:", error);
      showToast("Error", error.message, "error");
    } finally {
      setIsReplying(false);
    }
  };

  useEffect(() => {
    console.log(`Updated replyId: ${replyId}`);
  }, [replyId]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleReply();
    }
  };

  return (
    <>
      <Tooltip
        hasArrow
        label={"Comment"}
        placement="right"
        ml={1}
        openDelay={500}
        display={{ base: "block", md: "none" }}
        fontFamily={"'Orbitron', sans-serif"}
      >
        <Flex
          alignItems={"center"}
          gap={4}
          p={2}
          w={{ base: 10, md: "full" }}
          justifyContent={{ base: "center", md: "flex-start" }}
          onClick={handleOpen}
        >
          <FaRegCommentAlt size={20} color="#FF6EC7" />
          <Box
            display={{ base: "none", md: "block" }}
            fontFamily={"'Orbitron', sans-serif"}
            color="#8d8d8d"
            css={{
              textShadow:
                "0 0 2px yellow.400, 0 0 3px yellow.400, 0 0 4px yellow.400",
              mixBlendMode: "difference",
            }}
          >
            Comment
          </Box>
        </Flex>
      </Tooltip>

      <Modal onClose={handleClose} size={size} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent bg={"black"}>
          <ModalHeader
            fontFamily={"Orbitron, sans-serif"}
            color="rgb(135,206,250)"
          >
            <Button
              onClick={handleReply}
              style={{
                backgroundColor: "black",
                color: "#FF6EC7",
                borderColor: "#FF6EC7",
                borderWidth: "2px",
              }}
            >
              Reply
            </Button>
            <Text textAlign="center">Comments</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Type your comment here..."
              fontFamily={"Orbitron, sans-serif"}
              value={reply}
              onChange={(e) => setReply(sanitizeHtml(e.target.value))}
              onKeyDown={handleKeyDown}
            />

            {postCommentsData?.postComments.edges.map(
              ({ node: reply }, index) => (
                <Comment
                  key={reply.id}
                  reply={reply}
                  lastReply={
                    index === postCommentsData.postComments.edges.length - 1
                  }
                />
              ),
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Com;
 